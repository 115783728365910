import {Component, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {Router} from '@angular/router'
import {BankIdModule} from '@jhc/bankid'
import {filter} from 'rxjs'
import {environment} from '../../environments/environment'
import {HOME_ROUTE_PATH} from '../application/constants'
import {ConfigService} from '../services/config.service'

@Component({
  selector: 'foa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [BankIdModule]
})
export class LoginComponent implements OnInit {
  public bankIdUrl = environment.bankIdUrl
  public serviceType = environment.serviceType

  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    // Close all open dialogs. There may be cases that after being logged out,
    // some dialogs are still open and this can be annoying. Just close them all
    this.dialog.closeAll()

    this.configService.accessToken$
      .pipe(filter(Boolean))
      .subscribe({
        next: () => {
          this.router.navigate(['/', HOME_ROUTE_PATH]).then()
        }
      })
  }
}
