import {DatePipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatFormField, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatTab, MatTabGroup} from '@angular/material/tabs'
import {Subscription} from 'rxjs'
import '@angular/common/locales/global/fr'
import {IConsentItem, IPhotoProject} from '../../application/types'
import {
  PhotographerConsentsListComponent
} from '../../common/photographer-consents-list/photographer-consents-list.component'
import {
  PhotographerProjectsListComponent
} from '../../common/photographer-projects-list/photographer-projects-list.component'
import {FotoSessionService} from '../../services/foto-session.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-management-registered',
  templateUrl: './management-registered.component.html',
  styleUrls: ['./management-registered.component.scss'],
  standalone: true,
  imports: [MatFormField, MatInput, ReactiveFormsModule, MatIcon, MatSuffix, MatTabGroup, MatTab, PhotographerProjectsListComponent, PhotographerConsentsListComponent]
})
export class ManagementRegisteredComponent implements OnInit, OnDestroy {


  public filterControl = new FormControl<string>('', {nonNullable: true})

  public project = new FormControl('')

  public projects: IPhotoProject[] = []
  public sortedProjects: IPhotoProject[] = []

  public consents: IConsentItem[] = []
  public sortedConsents: IConsentItem[] = []
  public consentDates: Record<string, string | null> = {}

  private datePipe = new DatePipe('fr')
  private subs$: Subscription[] = []

  constructor(
    private fotoSessionService: FotoSessionService,
    private photoService: PhotoService
  ) {
  }

  /**
   * Initializes WebSocket communication by establishing a connection and starting a session.
   * Replace 'uffe' with a meaningful session identifier.
   * This function is called during component initialization.
   */
  private initializeWebSockets() {
    // Create a WebSocket session for 'uffe' (replace with a meaningful session identifier).
    const webSocketSubject = this.fotoSessionService.createWebSocket('uffe')

    // Start the WebSocket session.
    webSocketSubject?.next({
      type: 'start',
      sessionId: 'uffe'
    })

    // Subscribe to WebSocket messages to automatically refresh data when new completed signatures are received.
    webSocketSubject?.subscribe({
      next: (value) => {
        if (value) {
          this.refreshData()
        }
      }
    })
  }

  public ngOnInit() {
    this.initializeWebSockets()

    // Search bar filtering
    this.filterControl.valueChanges.pipe().subscribe(value => {
      const lowerCaseValue = value?.toLowerCase() || ''

      // Filter projects by name
      this.sortedProjects = this.projects.filter(project =>
        project.name.toLowerCase().includes(lowerCaseValue)
      )

      // Filter consents by name or description
      this.sortedConsents = this.consents.filter(consent =>
        // Filter by project name
        this.projects
          .find(p => p.projectId === consent.projectId)
          ?.name?.toLowerCase().includes(lowerCaseValue)
        // Filter by model name
        || consent.modelName?.toLowerCase().includes(lowerCaseValue)
        // Filter by date
        || this.consentDates[consent.itemId]?.includes(lowerCaseValue)
      )
    })

    this.photoService.getProjects().subscribe()

    this.subs$.push(this.photoService.photoProject$
      .subscribe(projects => {
        this.projects = projects
        this.sortedProjects = projects
      }))

    this.photoService.getConsents().subscribe()

    this.subs$.push(this.photoService.consents$
      .subscribe(consents => {
        this.consents = consents
        this.sortedConsents = consents

        // Whenever we receive new consents, we calculate their date in string
        // format, so we can filter them
        consents
          .forEach(c => {
            this.consentDates[c.itemId] =
              this.datePipe.transform(c.timeStamp, 'YYYY-MM-dd HH:mm')
          })
      }))
  }

  /**
   * Cleans up resources when the component is destroyed.
   * Closes the WebSocket session associated with 'uffe' (should not be Uffe).
   */
  public ngOnDestroy() {
    this.subs$.forEach((sub) => sub.unsubscribe())
    this.fotoSessionService.closeWebSocket('uffe')
  }

  public refreshData(): void {
    this.photoService.getConsents().subscribe({
      next: (serverItem: IConsentItem[]) => {
        this.consents = serverItem
        this.sortedConsents = serverItem
        this.sortedConsents.sort((a, b) =>
          b.timeStamp - a.timeStamp)
      }
    })
  }
}
