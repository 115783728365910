import {Component} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import {FooterComponent} from './common/footer/footer.component'
import {HeaderComponent} from './common/header/header.component'
import {ConfigService} from './services/config.service'

@Component({
  selector: 'foa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class AppComponent {
  constructor(public configService: ConfigService) {
  }
}
