<foa-management-sign-mode-header
  title="E-POST SIGNERING TILL PROJEKTET:"
></foa-management-sign-mode-header>

<h2 class="sign-title">E-post signering</h2>
<span class="information">Nedanstående information kommer att visas för modellen.</span>

<form [formGroup]="form" class="pre-sign-form-holder">
  <div class="input-holder">
    <label for="projectName">PROJEKT:</label>
    <input [value]="photoService.signSelectedProject$()?.name"
           id="projectName"
           placeholder="Information saknas"
           readonly>
  </div>
  <div class="input-holder">
    <label for="subject">ÄMNE:</label>
    <input formControlName="subject"
           id="subject"
           ngDefaultControl
           placeholder="Ex: Digital sanering">
  </div>

  <div class="input-holder wrap-placeholder">
    <label class="recipient-align" for="recipient">MOTTAGARE:
      <mat-icon #tooltip="matTooltip" (click)="tooltip.show()"
                (keydown.enter)="tooltip.show()"
                class="tooltip-icon"
                matTooltip="Epostadresser delas inte med modellerna.">info
      </mat-icon>
    </label>
    <mat-chip-grid #chipGrid aria-label="Mottagare">
      <mat-chip
        (removed)="removeRecipient(recipient.modelEmail)"
        *ngFor="let recipient of recipients">
        {{ recipient.modelEmail }}
        <button matChipRemove>
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-grid>

    <input
      (matChipInputTokenEnd)="addRecipient($event)"
      [matChipInputAddOnBlur]="true"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      formControlName="recipient"
      id="recipient"
      placeholder="Ex: exempel@exempel.com"
    >
  </div>

  <div class="text-area-holder">
    <label for="message">MEDDELANDE:</label>
    <textarea formControlName="message"
              id="message"
              ngDefaultControl
              placeholder="Ex: Här kommer en länk till signering av samtyckesavtal">
      </textarea>
  </div>
</form>

@if (emailService.isSendingPreSignEmail$()) {
  <foa-loader></foa-loader>
} @else {
  <button class="foa-button email-send-button" mat-flat-button
          [disabled]="recipients.length < 1 || form.invalid"
          (click)="sendPreSignEmail()">
    Skicka
  </button>
}
