<foa-management-sign-mode-header
  title="SINGELSIGNERING TILL PROJEKTET:"
></foa-management-sign-mode-header>

<h2 class="sign-title">Singelsignering</h2>

@if (!imageEvent.image) {
  <p class="sign-instructions">
    Lägg till en identifieringsbild för att hitta modellen i de bilder du tagit.
  </p>
}

<foa-image-handler
  (imageCaptured)="imageEvent = $event"
  [dualButtons]="true"
  [imageAsString]="imageEvent.image"
  [size]="100"
></foa-image-handler>

@if (imageEvent.image) {
  <hr class="sign-separator sign-mode-single-separator">

  <p class="sign-instructions">
    Om du är nöjd med bilden ovan är det dags att generera en QR-kod som
    modellen kan scanna:
  </p>

  @if (imageEvent.uploadedImage) {
    <button class="foa-button sign-mode-single-button" mat-flat-button
            (click)="goToQrCodeScreen()">
      Generera QR-kod för signering
    </button>
  } @else {
    <foa-loader></foa-loader>
  }
}

