<h1 class="title">
  Admin
</h1>

<p class="edit-profile-text"> REDIGERA PROFIL:</p>

<div class="name-holder">
  <mat-icon class="account-circle">account_circle</mat-icon>
  <span class="user-name-holder">
      {{ (configService.loggedInUser$ | async)?.name }}
    </span>
  <mat-icon (click)="editMyPage()"
            (keydown.enter)="editMyPage()"
            class="mat-icon-align"
            matSuffix>
    edit
  </mat-icon>
</div>

<span>REDIGERA PROJEKT</span>

<mat-form-field class="admin-search-bar foa-search-bar"
                subscriptSizing="dynamic">
  <input [formControl]="project" matInput>
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>

<div class="admin-project-title-and-add">
  <span>PROJEKT:</span>

  <button (click)="createProject()" mat-icon-button matSuffix>
    <mat-icon>add_circle</mat-icon>
  </button>
</div>

<div class="project-form-holder">
  @if (projectData.length !== 0) {
    <div class="project-holder-header">
      <span class="name-text">Namn:</span>
      <span class="active-text">Aktiv:</span>
    </div>
  }

  <foa-photographer-projects-list
    [actionButtons]=true
    [consents]="consents"
    [projects]="projectData">
  </foa-photographer-projects-list>
</div>
