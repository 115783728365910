import {DatePipe} from '@angular/common'
import {Component, Inject, Input} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {IConsentItem, IPhotoProject} from '../../application/types'
import {WINDOW} from '../../application/window.provider'
import {
  ConsentDetailsDialogComponent
} from '../../dialogs/consent-details-dialog/consent-details-dialog.component'
import {PhotoService} from '../../services/photo.service'
import {LoaderComponent} from '../loader/loader.component'

@Component({
  selector: 'foa-photographer-consents-list',
  standalone: true,
  imports: [
    DatePipe,
    MatIcon,
    LoaderComponent
  ],
  templateUrl: './photographer-consents-list.component.html',
  styleUrl: './photographer-consents-list.component.scss'
})
export class PhotographerConsentsListComponent {
  @Input({required: true}) projects: IPhotoProject[] = []
  @Input({required: true}) consents: IConsentItem[] = []
  @Input() allConsents: boolean = false

  constructor(
    @Inject(WINDOW) private injectedWindow: Window,
    public photoService: PhotoService,
    private consentDetailsDialog: MatDialog
  ) {
  }

  public consentDetails(consentItem: IConsentItem) {
    // Get project that this consent belongs to
    const project = this.projects
      .find(p => p.projectId === consentItem.projectId)

    ConsentDetailsDialogComponent
      .open(this.consentDetailsDialog, consentItem, project)
  }

  public downloadPdf(itemId: string): void {
    this.photoService.downloadPDF(itemId)
      .subscribe({
        next: (url: string) =>
          this.injectedWindow.open(url, '_blank')
      })
  }
}
