import {HttpClient} from '@angular/common/http'
import {Inject, Injectable, signal} from '@angular/core'
import {MatSnackBar} from '@angular/material/snack-bar'
import {Router} from '@angular/router'
import {HelperService} from '@jhc/bankid'
import {BehaviorSubject, filter, Observable, tap, timer} from 'rxjs'
import {environment} from '../../environments/environment'
import {ACCESS_TOKEN_NAME, LOGIN_ROUTE_PATH} from '../application/constants'
import {LOCAL_STORAGE} from '../application/local-storage.provider'
import {IUser} from '../application/types'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  /**
   * Signal informing if there is a logged user or not. Useful for AuthGuard or
   * template files
   */
  public isLoggedIn$ = signal(false)

  public loggedInUser$: Observable<IUser | null>
  public pLoggedInUser$: BehaviorSubject<IUser | null> =
    new BehaviorSubject<IUser | null>(null)

  /**
   * Subject that provides an access token if there is a user logged in. Useful
   * for "logic files" (.ts files), specially LoginComponent
   */
  public accessToken$ = new BehaviorSubject<string | null>(null)

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    this.loggedInUser$ = this.pLoggedInUser$.asObservable()
  }

  public bootstrap() {
    // We start checking for token updated, and whenever we receive a new value,
    // we start the expiration checking, and we recover the logged-in user
    this.accessToken$
      .pipe(
        // Logged-in signal is updated with every new value of access token,
        // even if null, which will mean that no one is logged in.
        tap((token) => this.isLoggedIn$.set(!!token)),
        filter(Boolean)
      )
      .subscribe(() => {
        this.getUser().subscribe()
        this.checkTokenExpiration()
      })

    // We set the initial accessToken value, which can be null
    this.accessToken$
      .next(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
  }

  public setToken(accessToken: string): void {
    // First thing, save the token
    this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, accessToken)

    // Then, send all required events related to it
    this.accessToken$.next(accessToken)
  }

  public checkLogoutTime(): number {
    const token: any = HelperService.GetTokenPayload(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    if (token) {
      return token.exp
    } else {
      return -1
    }
  }

  public getCurrentUserPnr(): string {
    const token = HelperService.GetTokenPayload(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    return token?.sub ?? ''
  }

  public logout(doNotNavigate: boolean = false): void {
    // Remove token for storage
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)

    // Reset all event values
    this.accessToken$.next(null)

    // Go to log-in (if needed)
    if (!doNotNavigate) {
      this.router.navigate([LOGIN_ROUTE_PATH]).then()
    }
  }

  public updateUser(user: IUser) {
    const url = `${environment.apiUrl}/user`
    return this.httpClient.put<IUser>(url, {...user, photoUrl: null})
      .pipe(
        tap((val) => this.pLoggedInUser$.next(val))
      )
  }

  public getUser(): Observable<IUser> {
    const url = `${environment.apiUrl}/user`
    return this.httpClient.get<IUser>(url).pipe(
      tap((user: IUser) => {
        this.pLoggedInUser$.next(user)
      })
    )
  }

  private checkTokenExpiration() {
    const expiryTime = this.checkLogoutTime()
    const expiryAccuracy = 60 * 3 * 1000 // 3 minutes accuracy to the timer, ie how long can we be logged in with an expired token
    const timer$ = timer(0, expiryAccuracy).subscribe({
      next: () => {
        /**
         * configService returns -1 if no token, make sure we are logged out,
         * but stay where we are.
         * There are pages that do not require login so routing is bad
         * */
        if (expiryTime < 0) {
          // It will log out the user, without navigating to login page
          this.logout(true)
          // Unsubscribe from timer after doing all needed tasks
          timer$.unsubscribe()
        }
        /**
         * we had a token, and it has expired, ie we are not a visitor,
         * log out and route to login page
         * */
        else if (Date.now() > expiryTime) {
          // Dismiss any possible open snackbar
          this.snackBar.dismiss()
          // It will log out the user and navigate to login page
          this.logout()
          // Unsubscribe from timer after doing all needed tasks
          timer$.unsubscribe()
        }
        /**
         * when there is little time left on the token let the user know
         * */
        else if (Date.now() > expiryTime - expiryAccuracy) {
          this.snackBar.open(
            'Din session går ut om mindre är 3 minuter. Efter det måste du logga in igen.',
            'Okej!'
          )
        }
      }
    })
  }
}
