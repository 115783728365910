<h1 class="title">
  Signerade
</h1>

<div class="search-field-holder">
  <mat-form-field class="foa-search-bar" subscriptSizing="dynamic">
    <input [formControl]="filterControl" matInput>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>

<mat-tab-group animationDuration="0ms" class="foa-tab-group">
  <mat-tab label="PROJEKT:">
    <div class="list-holder">
      <foa-photographer-projects-list
        [consents]="sortedConsents"
        [projects]="sortedProjects">
      </foa-photographer-projects-list>
    </div>
  </mat-tab>
  <mat-tab label="SENASTE:">
    <div class="list-holder">
      <foa-photographer-consents-list
        [allConsents]="true"
        [consents]="sortedConsents"
        [projects]="projects"
      ></foa-photographer-consents-list>
    </div>
  </mat-tab>
</mat-tab-group>


