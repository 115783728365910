import {AsyncPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms'
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatFormField, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {Router} from '@angular/router'
import {filter, Subscription} from 'rxjs'
import {environment} from '../../../environments/environment'
import {ROUTE_PARAM_PROJECT_ID, SIGN_MODE_MULTI_ROUTE_PATH, SIGN_SELECT_ROUTE_PATH} from '../../application/constants'
import {IPhotoProject} from '../../application/types'
import {LoaderComponent} from '../../common/loader/loader.component'
import {EditProjectDialogComponent} from '../../dialogs/edit-project-dialog/edit-project-dialog.component'
import {ConfigService} from '../../services/config.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-management-home',
  standalone: true,
  imports: [
    AsyncPipe,
    MatIcon,
    MatIconButton,
    MatSuffix,
    FormsModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatFormField,
    MatInput,
    MatOption,
    ReactiveFormsModule,
    MatButton,
    LoaderComponent
  ],
  templateUrl: './management-home.component.html',
  styleUrl: './management-home.component.scss'
})
export class ManagementHomeComponent implements OnInit, OnDestroy {

  public filteredProjects: IPhotoProject[] = []
  public projects: IPhotoProject[] = []
  public project = new FormControl('', {nonNullable: true})
  public options: string[] = []
  public firstName: string = ''
  public subs$: Subscription [] = []


  constructor(
    private configService: ConfigService,
    protected photoService: PhotoService,
    private editProjectDialog: MatDialog,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    // Reset selected project
    this.photoService.signSelectedProject$.set(null)

    // Retrieve all projects from service
    this.photoService.getProjects().subscribe()

    // Subscribe to any change in photo projects
    this.subs$.push(this.photoService.photoProject$
      .subscribe(projects => {
        this.filteredProjects = projects
        this.projects = projects
      }))

    // Search field sorting
    this.project.valueChanges.pipe().subscribe(value => {
      this.filteredProjects = this.projects.filter(project =>
        project.name.toLowerCase().startsWith(value.toLowerCase()))
      this.options = this.filteredProjects.map(value => value.name)
    })

    // Extract the first name of the user to display
    // Takes the first part of the users name separated by a space
    // Problematic if the user has a double name without a hyphen
    this.subs$.push(this.configService.loggedInUser$
      .pipe(filter(Boolean))
      .subscribe(user => {
        if (user.name) {
          const splitName = user.name?.split(' ')
          this.firstName = splitName[0]
        } else {
          this.firstName = ''
        }
      }))
  }

  public ngOnDestroy() {
    this.subs$.forEach((sub) => sub.unsubscribe())
  }

  public createProject() {
    this.editProjectDialog.open(EditProjectDialogComponent, {
      data: {
        project: {
          id: '',
          name: '',
          description: '',
          active: true
        }
      }
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(result => {
        this.photoService.createProject(result).subscribe({})
      })
  }

  public openSignSelect(project: IPhotoProject) {
    this.photoService.signSelectedProject$.set(project)
    this.router.navigate(
      ['/', SIGN_SELECT_ROUTE_PATH, project.projectId]).then()
  }

  protected readonly environment = environment
  protected readonly ROUTE_PARAM_PROJECT_ID = ROUTE_PARAM_PROJECT_ID
  protected readonly SIGN_MODE_MULTI_ROUTE_PATH = SIGN_MODE_MULTI_ROUTE_PATH
}
