@if (display) {
  @if (sessionState) {
    <h2 class="title">Samtyckessignering</h2>

    <p class="information-text">
  <span class="photographer-name">
    {{ (fotoSessionService.photographer$ | async)?.name }}
  </span>
      önskar samtycke för användning av de bilder de tagit på dig.
    </p>

    <!-- Photographer image and name -->
    <foa-image-handler
      [circularImage]="true"
      [imageAsString]="image"
      [size]="55"
    ></foa-image-handler>
    <span class="photographer-name">
  {{ (fotoSessionService.photographer$ | async)?.name }}
</span>

    <div class="authorization-holder">
      <div class="verified-holder">
        <mat-icon class="verified-icon">verified</mat-icon>
        <span>  - Auktoriserad av <b>Seal the Deal Consent</b>.</span>
      </div>

      <div class="bankid-holder">
        <img alt="" class="bankid-logo" src="assets/BankID_logo.svg">
        <span>  - Legitimerad med <b> BankID</b>.</span>
      </div>
    </div>

    <!-- If there is a photo ID present, we are coming from a Single Sign
      In that case will directly sign in this screen -->
    @if (photoId) {
      <jhc-bankid
        (accessToken)="onSignWithPhotoFromSingleSign($event)"
        [apiUrl]="environment.apiUrl"
        [bankIdUrl]="environment.bankIdUrl"
        [serviceType]="environment.serviceType"
        [userRef]="sessionId"
      ></jhc-bankid>
      <button class="foa-button minor-button" mat-flat-button
              (click)="minorMoveOn()">
        Signera för minderårig
      </button>
    } @else {
      <button class="foa-button continue-button" mat-flat-button
              (click)="moveOn()">
        Vidare
      </button>
    }
  } @else {
    <div class="session-error">
      <h3 class="error-title">
        <mat-icon class="warning-icon">warning</mat-icon>
        Sessionen har löpt ut.
      </h3>
      <br>
      <br>
      <span>Vänligen kontakta fotografen för att skapa en ny session.</span>
    </div>
  }
}
