<foa-management-sign-mode-header
  title="MULTISIGNERING TILL PROJEKTET:"
></foa-management-sign-mode-header>

<h2 class="sign-title">Multisignering</h2>

<p class="sign-instructions">
  Be de personer du söker samtycke ifrån att scanna QR-koden för att ta en
  matchningsbild:
</p>

<jhc-qr-code [data]="signUrl"></jhc-qr-code>

<div class="multi-button-holder">
  <button (click)="close()" class="foa-button" mat-flat-button>Stäng</button>

  <!-- "Continue" button that is only here for testing cases -->
  <!-- Real cases will scan QR code and continue process in other device -->
  @if (!environment.production) {
    <button class="foa-button" (click)="moveOn()" mat-flat-button>
      Gå vidare
    </button>
  }
</div>
