<div matDialogTitle>Länk</div>

<div matDialogContent>
  <div class="information-holder">
    <input
      id="modelSub"
      placeholder="Information saknas"
      readonly
      value="https://sealthedeal.se/...">
  </div>
</div>
<div class="link-buttons-holder" matDialogActions>
  <button (click)=copyLink() [mat-dialog-close]="null" class="foa-button" mat-flat-button>
    Kopiera länk och stäng
  </button>
</div>
