<foa-management-sign-mode-header
  title="SINGEL-SIGNERING TILL PROJEKTET:"
></foa-management-sign-mode-header>

<p class="sign-instructions">
  Be personen/modellen du önskar samtycke från att scanna QR-koden:
</p>

<jhc-qr-code [data]="signUrl"></jhc-qr-code>

<div class="single-code-button-holder">
  <button (click)="close()" class="foa-button" mat-flat-button>Stäng</button>

  <!-- "Continue" button that is only here for testing cases -->
  <!-- Real cases will scan QR code and continue process in other device -->
  @if (!environment.production) {
    <button class="foa-button" (click)="moveOn()" mat-flat-button>
      Gå vidare
    </button>
  }
</div>