<div class="main">
  <div class="header-holder">
    <div class="header">
      <div [routerLink]="[LOGIN_ROUTE_PATH]" class="logo-holder">
        <img alt="foto logo" src="assets/logo.svg">
      </div>
      <div class="menu-holder">
        <div class="user-name-holder">
          {{ configService.isLoggedIn$() ? (configService.loggedInUser$ | async)?.name : '' }}
        </div>
        <mat-icon
          [matTooltip]="configService.isLoggedIn$() ? 'Logged in' : 'Not logged in'"
          class="icon">
          <div>
            {{ configService.isLoggedIn$() ? 'account_circle' : 'person_off' }}
          </div>
        </mat-icon>
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon class="icon">menu</mat-icon>
        </button>
        <mat-menu #menu>
          @if (configService.isLoggedIn$()) {
            <button [routerLink]="HOME_ROUTE_PATH" mat-menu-item>
              Signera
            </button>
            <button [routerLink]="REGISTERED_ROUTE_PATH" mat-menu-item>
              Signerade
            </button>
            <button [routerLink]="ADMIN_ROUTE_PATH" mat-menu-item>
              Admin
            </button>
            <button [routerLink]="ABOUT_ROUTE_PATH" mat-menu-item>
              Om tjänsten
            </button>
            <button (click)="configService.logout()" mat-menu-item>
              Logga ut
            </button>

          } @else {
            <button [routerLink]="[LOGIN_ROUTE_PATH]" mat-menu-item>
              Logga in
            </button>
            <button [routerLink]="ABOUT_ROUTE_PATH" mat-menu-item>
              Om tjänsten
            </button>
          }
        </mat-menu>
      </div>
    </div>
  </div>
</div>
