<h2 class="title">Om tjänsten</h2>
<br>
<br>
<span>
  <b>Seal the Deal Consent</b> är en tjänst som samlar samtyckesavtal för
      både privatpersoner och företag/organisationer.
</span>
<br>
<br>
<span>
  <b>Som privatperson</b> blir Seal the Deal Consent det enkla sättet
      till överblick av de samtyckesavtal man godkänt. De kan också administreras
      , återkallas och ändras om avtalen uppdateras.
</span>
<br>
<br>
<span>
  <b>För fotografer</b> finns en utökad tjänst med möjlighet att skapa
      samtyckesavtal, be om signeringar genom BankId samt få en fullständig koll
      på de avtal som finns, hur länge de gäller och vilka bilder de berör
</span>
<br>
<br>
<span>
  <b>Är du fotograf</b> och vill prova vår utökade tjänst samt veta mer
      om betalningsupplägg ber vi dig <b
  class="underline-url">kontakta oss.</b>
</span>
<br>
<br>
<span>
  <b>Vår tjänst är så trygg den kan bli</b> för alla parter i ett avtal.
      Vi kräver auktorisering av alla fotografer som använder tjänsten. Tjänsten
      kan inte användas inkognito och alla överträdelser av Svensk lag leder till
      evig uteslutning.
</span>


