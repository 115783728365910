import {HttpClient} from '@angular/common/http'
import {Injectable, signal, WritableSignal} from '@angular/core'
import {BehaviorSubject, filter, Observable, tap} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {IConsentItem, IPhotoProject} from '../application/types'

interface ISignature {
  sub: string
  iat: string
  exp: string
  dmn: string
  ref: string
}

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  public isConsentsLoading$ = signal(true)
  public isProjectsLoading$ = signal(true)
  public signSelectedProject$: WritableSignal<IPhotoProject | null> =
    signal(null)

  public photoProject$: Observable<IPhotoProject[]>
  public consents$: Observable<IConsentItem[]>

  private pPhotoProject$: BehaviorSubject<IPhotoProject[]> =
    new BehaviorSubject<IPhotoProject[]>([])
  private cConsents$: BehaviorSubject<IConsentItem[]> =
    new BehaviorSubject<IConsentItem[]>([])

  constructor(private httpClient: HttpClient) {
    this.photoProject$ = this.pPhotoProject$.asObservable()
    this.consents$ = this.cConsents$.asObservable()
  }

  public getProjects(): Observable<IPhotoProject[]> {
    this.isProjectsLoading$.set(true)
    const url = `${environment.apiUrl}/project`

    return this.httpClient.get<IPhotoProject[]>(url).pipe(
      tap((projects: IPhotoProject[]) => {
        // Sort recent projects on top
        projects.sort((a, b) =>
          b.timestamp - a.timestamp)
        // Sort the projects that are active on top
        projects.sort((a, b) => {
          if (a.active === b.active) {
            return 0
          } else if (a.active) {
            return -1
          } else {
            return 1
          }
        })
        // Update the BehaviorSubject with the retrieved projects
        this.pPhotoProject$.next(projects)
        this.isProjectsLoading$.set(false)
      })
    )
  }

  public createProject(project: IPhotoProject): Observable<IPhotoProject[]> {
    const url = `${environment.apiUrl}/project`
    return this.httpClient.put<void>(url, project)
      .pipe(switchMap(() => this.getProjects()))
  }

  public saveProject(project: IPhotoProject): Observable<IPhotoProject[]> {
    const url = `${environment.apiUrl}/project/${project.projectId}`
    return this.httpClient.put<void>(url, project)
      .pipe(switchMap(() => this.getProjects()))
  }

  public deleteProject(id: string): Observable<any> {
    const url = `${environment.apiUrl}/project/${id}`

    return this.httpClient.delete<any>(url).pipe(
      switchMap(() =>
        this.getProjects()
      )
    )
  }

  public getConsents(): Observable<IConsentItem[]> {
    this.isConsentsLoading$.set(true)
    const url = `${environment.apiUrl}/foto`
    return this.httpClient.get<IConsentItem[]>(url).pipe(
      tap((consents: IConsentItem[]) => {
        // Sort the latest consents on top
        consents.sort((a, b) =>
          b.timeStamp - a.timeStamp)
        // Update the BehaviorSubject with the latest consents
        this.cConsents$.next(consents)
        this.isConsentsLoading$.set(false)
      })
    )
  }

  public addInfoToConsent(itemId: string, photoId?: string, modelDescription?: string): Observable<void> {
    const url = `${environment.apiUrl}/foto/${itemId}`
    return this.httpClient.put<void>(url,
      {
        'photoId': photoId,
        'modelDescription': modelDescription
      })
  }

  public deleteConsent(itemId: string): Observable<IConsentItem[]> {
    const url = `${environment.apiUrl}/foto/${itemId}`
    return this.httpClient.delete<string>(url).pipe(
      switchMap(() => this.getConsents())
    )
  }

  public getConsentById(itemId: string): Observable<IConsentItem> {
    const url = `${environment.apiUrl}/foto/${itemId}`
    return this.httpClient.get<any>(url)
  }

  public signCompleted(
    accessToken: string,
    photoId?: string,
    modelDescription?: string
  ): Observable<string> {
    const url = `${environment.apiUrl}/collect`
    return this.httpClient.put<string>(url, accessToken)
      .pipe(
        filter(Boolean),
        map((token) => {
          const tokenParts = token.split('.')
          const signature = JSON.parse(atob(tokenParts[1])) as ISignature
          return signature.ref
        }),
        switchMap((signatureRef) => {
          return this.addInfoToConsent(
            signatureRef,
            photoId,
            modelDescription
          ).pipe(map(() => signatureRef))
        })
      )
  }

  public downloadPDF(itemId: string): Observable<string> {
    const url = `${environment.apiUrl}/pdf/${itemId}`
    return this.httpClient.get<string>(url)
  }

  public sendArkad(): Observable<any> {
    const url = `${environment.apiUrl}/arkad`
    return this.httpClient.put<void>(url, url)
  }
}
