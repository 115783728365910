export interface IFileUpload {

  /**
   * The name of the file
   */
  name: string

  /**
   * The content type, we will possibly use this
   * later.
   */
  contentType: string

  /**
   * Image data is a "Blob"?
   */
  imageData: Blob

  /**
   * The id as received from the server
   */
  id?: string
}

export interface ISession {
  /**
   * Our session id to keep track of several signs
   */
  sessionId: string
  /**
   * Session expiry time
   */
  expiryTime: number
  /**
   * The personnummer of photographer.
   */
  photographerSub: string
  /**
   * The name of photographer. We will save it here, even when not fully
   * necessary, so we can use it when sending emails.
   */
  photographerName: string
  /**
   * Session timestamp
   */
  timeStamp: number
  /**
   * Project's ID connected to the session
   */
  projectId?: string
  /**
   * Model email if it is a pre-sign session
   */
  modelEmail?: string
}

/**
 * Session containing the session and user objects.
 * Used to recover the necessary information to display to the model.
 */
export class Session {
  session: ISession
  user: IUser

  constructor(session: ISession, user: IUser) {
    this.session = session
    this.user = user
  }
}

export interface IConsentItem {
  /**
   * (not required) Description for the subject/model
   * @deprecated It should be replaced with {@link modelDescription}
   */
  description?: string
  /**
   * The personnummer of the model also called subject
   * @deprecated It should be replaced with {@link modelSub}
   */
  photoSubjectSub?: string
  /**
   * potential project info
   * @deprecated It should be removed, and use {@link projectId} instead
   */
  project?: IPhotoProject


  /**
   * The itemId same as orderRef. Usually an "orderRef" coming from BankID
   * after signing the consent.
   */
  itemId: string
  /**
   * Our session ID to keep track of several signs
   */
  sessionId: string
  /**
   * Associated project ID. It is not mandatory, there can be consents not
   * associated to a project.
   */
  projectId?: string
  /**
   * When it should be ttl deleted. It is used to create items in DB before
   * signing, os they can get removed automatically if they never get signed.
   * This parameter should be removed once the consent is signed.
   */
  expiryTime?: number
  /**
   * Date in milliseconds of when it is created
   */
  timeStamp: number
  /**
   * Photographer's personnummer.
   */
  photographerSub: string
  /**
   * Photographer's name.
   * Since we plan to open consents to models in the future, it might be a good
   * idea that they don't make API calls to recover other user (photographer),
   * so we store their photographer name here too.
   */
  photographerName: string
  /**
   * (Currently not in use - 08/08/2024)
   * String of agreement. This is basically the contact, in text, that both
   * parts are signing.
   */
  agreement: string
  /**
   * (Currently not in use - 08/08/2024)
   * Don't know right now if we want to use this for some reason.
   */
  version: number
  /**
   * (Currently not in use - 08/08/2024)
   * Company's sub. Maybe photographers are part of a company.
   */
  company?: string
  /**
   * (Currently not in use - 08/08/2024)
   * Customer's sub. Maybe models are part of a company.
   */
  customer?: string
  /**
   * The personnummer of the model also called subject.
   */
  modelSub: string
  /**
   * Model's name.
   * It should be mandatory, but we are currently gathering it from BankID,
   * so just in case, we make it optional.
   */
  modelName?: string
  /**
   * Model's email.
   */
  modelEmail?: string
  /**
   * Model's description.
   * It must be filled if no photo is present.
   */
  modelDescription?: string
  /**
   * Photo ID of model. It will be used to retrieve a photo URL from AWS S3.
   * If it is not present, then {@link modelDescription} must be.
   */
  photo?: string
  /**
   * Secured URL of model's photo from AWS S3.
   * It should not be stored in DB
   */
  photoUrl?: string
}

/**
 * Social medias available in our application.
 */
export const SocialMedias = ['webpage', 'instagram', 'facebook', 'unsplash', 'linkedin'] as const
/**
 * Type of social medias available in our application.
 */
export type TSocialMedia = typeof SocialMedias[number]

/**
 * Type that ensured that all possible social medias are parameters of an
 * object. Their value is an optional string.
 */
export type TSocialMedias = { [key in TSocialMedia]?: string }

/**
 * User model, also known as photographer.
 */
export interface IUser extends TSocialMedias {
  /**
   * User's personnummer
   */
  sub: string
  /**
   * User's name
   */
  name: string
  /**
   * User's email
   */
  email?: string
  /**
   * User's phone number
   */
  phoneNumber?: string
  /**
   * Photo ID of photographers' profile picture. It will be used to retrieve a
   * photo URL from AWS S3.
   */
  photo?: string
  /**
   * Secured URL of photographer's profile picture from AWS S3.
   * It should not be stored in DB
   */
  photoUrl?: string
  /**
   * Photographer's thank-you message
   */
  thankYouMessage?: string
  /**
   * (Currently not in use - 08/08/2024)
   * Employer registered number
   */
  employer?: string
  /**
   * (Currently not in use - 08/08/2024)
   * Version number of the user.
   */
  version?: number
}

export interface IPhotoProject {
  /**
   * Project's ID. Usually a random UUID.
   */
  projectId: string
  /**
   * Project's name
   */
  name: string
  /**
   * Project's description.
   */
  description?: string
  /**
   * Flag that indicates if project is active.
   * Non-active projects should not appear in "selection screens".
   */
  active: boolean
  /**
   * Photographer/User personnummer associated to project
   */
  photographerSub: string
  /**
   * Date in milliseconds of when project is created
   */
  timestamp: number
  /**
   * Flag that indicates if project is disabled. It is used to not delete a
   * project, but to keep it "unreachable". So we don't lose data.
   * Those projects that are disabled should not be sent to FE.
   */
  disabled?: boolean
}

export interface IPreSignModel {
  modelEmail: string
}

export interface IMinorSignModel {
  guardianEmail: string
}

export interface IKidModel {
  pNr: string
}

export interface IImageResponse {
  id: string
  signedUrl: string
}
