import {DatePipe, SlicePipe} from '@angular/common'
import {Component, Inject} from '@angular/core'
import {ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef
} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {filter} from 'rxjs'
import {IConsentItem, IPhotoProject} from '../../application/types'
import {PhotoService} from '../../services/photo.service'
import {RegretDialogComponent} from '../regret-dialog/regret-dialog.component'

@Component({
  selector: 'foa-consent-details-dialog',
  standalone: true,
  imports: [
    MatIcon,
    MatInput,
    ReactiveFormsModule,
    SlicePipe,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    DatePipe
  ],
  templateUrl: './consent-details-dialog.component.html',
  styleUrl: './consent-details-dialog.component.scss'
})
export class ConsentDetailsDialogComponent {
  public consent: IConsentItem
  public project?: IPhotoProject

  constructor(
    private consentDetailsDialogRef: MatDialogRef<ConsentDetailsDialogComponent>,
    private regretDialog: MatDialog,
    private photoService: PhotoService,
    @Inject(MAT_DIALOG_DATA) data: {
      consent: IConsentItem,
      project?: IPhotoProject
    }
  ) {
    this.consent = data.consent
    this.project = data.project
  }

  public static open(
    dialog: MatDialog,
    consent: IConsentItem,
    project?: IPhotoProject
  ) {
    return dialog.open(ConsentDetailsDialogComponent, {
      data: {consent, project},
      width: '100%',
      maxWidth: 'min(400px, 85vw)',
      height: '100%',
      maxHeight: 'min(700px, 95vh'
    })
  }

  public openDialog(itemId: string): void {
    this.regretDialog.open(RegretDialogComponent, {
      data: {
        title: 'Borttagning av samtycke',
        body: 'Detta tar bort all information och går inte att ångra!'
      }
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: () => {
          this.photoService.deleteConsent(itemId).subscribe()
          this.consentDetailsDialogRef.close(true)
        }
      })
  }
}
