<h1>Välkommen!</h1>

<p>För att använda <b>Seal the Deal Consent</b>
  behöver du identifiera dig med BankID.</p>

<jhc-bankid
  (accessToken)="configService.setToken($event)"
  [bankIdUrl]="bankIdUrl"
  [serviceType]="serviceType"
></jhc-bankid>
