import {Component, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {ActivatedRoute, Router} from '@angular/router'
import {environment} from '../../../environments/environment'
import {
  HOME_ROUTE_PATH,
  MODEL_ROUTE_PATH,
  MODEL_WELCOME_ROUTE_PATH,
  ROUTE_PARAM_PROJECT_ID,
  SIGN_MODE_EMAIL_ROUTE_PATH,
  SIGN_MODE_MULTI_ROUTE_PATH,
  SIGN_MODE_SINGLE_ROUTE_PATH
} from '../../application/constants'
import {LinkDialogComponent} from '../../dialogs/link-dialog/link-dialog.component'
import {FotoSessionService} from '../../services/foto-session.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-management-sign-select',
  standalone: true,
  imports: [
    MatIcon,
    MatButton
  ],
  templateUrl: './management-sign-select.component.html',
  styleUrls: ['../sign-styles.scss', './management-sign-select.component.scss']
})
export class ManagementSignSelectComponent implements OnInit {

  protected generatedSessionId: string = ''
  private signUrl: string = ''
  private theProjectId: string = ''

  constructor(
    protected photoService: PhotoService,
    protected fotoSessionService: FotoSessionService,
    private router: Router,
    private route: ActivatedRoute,
    private linkDialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const projectId = params.get(ROUTE_PARAM_PROJECT_ID)!

      // Recover selected project from service and check it is the same we
      // have in params. If not, navigate to home screen.
      if (!projectId || this.photoService.signSelectedProject$()?.projectId !== projectId) {
        this.router.navigate(['/', HOME_ROUTE_PATH]).then()
      } else {
        this.theProjectId = projectId
      }
    })
  }

  public changeProject() {
    this.router.navigate([HOME_ROUTE_PATH]).then()
  }

  public startSingleSign() {
    this.router.navigate(
      ['/', SIGN_MODE_SINGLE_ROUTE_PATH,
        this.photoService.signSelectedProject$()?.projectId])
      .then()
  }

  public startMultiSign() {
    this.router.navigate(
      ['/', SIGN_MODE_MULTI_ROUTE_PATH,
        this.photoService.signSelectedProject$()?.projectId])
      .then()
  }

  public startPreSign() {
    this.router.navigate(
      ['/', SIGN_MODE_EMAIL_ROUTE_PATH,
        this.photoService.signSelectedProject$()?.projectId])
      .then()
  }

  public startLinkSign() {
    this.fotoSessionService.createSessionData(this.theProjectId)
      .subscribe({
        next: (session) => {
          this.signUrl = environment.theUrl + '#/' + `${MODEL_ROUTE_PATH}/${MODEL_WELCOME_ROUTE_PATH}/` + session.sessionId
          this.linkDialog.open(LinkDialogComponent, {
            data: this.signUrl
          })
        }
      })
  }
}
