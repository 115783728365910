@if (photoService.isConsentsLoading$() && consents.length === 0) {
  <foa-loader></foa-loader>
} @else {
  @for (consent of consents; track $index) {
    <div class="project-content-holder">
      <div class="project-content">
        <div class="sign-photo-holder">
          @if (consent.photo) {
            <img class="sign-photo" alt="" tabindex="-1"
                 [src]="consent.photoUrl"
                 (keydown.enter)="consentDetails(consent)"
                 (click)="consentDetails(consent)">
          } @else {
            <mat-icon class="default-photo-icon">image</mat-icon>
          }
        </div>

        <p
          class="registered-date"
          tabindex="-1"
          (keydown.enter)="consentDetails(consent)"
          (click)="consentDetails(consent)">
          {{ consent.timeStamp | date: 'YYYY-MM-dd HH:mm': undefined : 'fr' }}
        </p>

        <mat-icon class="pdf-icon"
                  (keydown.enter)="downloadPdf(consent.itemId)"
                  (click)="downloadPdf(consent.itemId)">
          picture_as_pdf
        </mat-icon>
      </div>
    </div>
  } @empty {
    @if (allConsents) {
      <span class="no-consents-all">Samtycken saknas.</span>
    } @else {
      <span>Detta projekt saknar samtycken.</span>
    }
  }
}