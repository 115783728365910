<form [formGroup]="projectForm">
  <div class="proof-holder" matDialogContent>
    @if (data.project.name === '') {
      <p class="create-project-text">SKAPA PROJEKT:</p>
    } @else {
      <p>REDIGERA PROJEKT:</p>
      <div class="project-display-holder">
        <mat-icon class="folder-icon" matPrefix>folder</mat-icon>
        <span class="project-name-holder">{{ data.project.name }}</span>

        <div class="mat-icon-align">
          <mat-icon (keydown.enter)="deleteProject(data.project.projectId)"
                    (click)="deleteProject(data.project.projectId)">delete
          </mat-icon>
          <mat-checkbox (change)="changeActive($event, data.project)"
                        [checked]=data.project.active>
          </mat-checkbox>
        </div>
      </div>
    }
    <div>
      <div class="input-holder-name">
        <label for="name">PROJEKTNAMN:</label>
        <input [value]="data.project.name" formControlName="name"
               id="name" placeholder="Ex. Stadsfesten 2024">
      </div>
      <div class="input-holder-description">
        <label for="description">BESKRIVNING:</label>
        <input [value]="data.project.description" formControlName="description"
               id="description"
               placeholder="Ex. Stadsfesten i folketspark">
      </div>
      @if (data.project.name !== '') {
        <p class="consent-title">SAMTYCKEN:</p>
        <foa-photographer-consents-list
          [projects]="[data.project]"
          [consents]="consents | filterConsents : projectId"
        ></foa-photographer-consents-list>
      }
    </div>

    <div class="edit-project-buttons-holder">
      <button [mat-dialog-close]="null" class="foa-button" mat-flat-button>
        Stäng
      </button>

      <button (click)="close()" [disabled]="projectForm.invalid"
              [mat-dialog-close]="true"
              class="foa-button"
              mat-flat-button
      >
        Spara
      </button>
    </div>
  </div>
</form>
