<div class="image-handler">
  @if (imageAsString !== '') {
    <div class="image-holder">
      <img class="image" [class.circular-image]="circularImage" [src]="imageAsString"
           [style.width.%]="size"
           alt="selfie">
    </div>
  }

  @if (imageAsString !== '' && retakeButton) {
    <button class="re-take-photo" (click)="openCam()" mat-flat-button>
      Ta om
    </button>
  }

  @if (dualButtons) {
    <div class="image-button-holder">
      <button (click)="openCam()" class="foa-button image-button"
              mat-flat-button>
        <mat-icon>photo_camera</mat-icon>
      </button>
      <input #fileUpload (change)="onFileChanged($event)"
             accept=".json, .pdf, .png, .jpg" class="file-input"
             style="display:none;" type="file"/>
      <button (click)="fileUpload.click()" class="foa-button image-button"
              mat-flat-button>
        <mat-icon> image</mat-icon>
      </button>
    </div>
  }
</div>
