import {Component} from '@angular/core'

@Component({
  selector: 'foa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true
})
export class FooterComponent {

}
