import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http'
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom
} from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {MatButtonModule} from '@angular/material/button'
import {MatDialogModule} from '@angular/material/dialog'
import {MatExpansionModule} from '@angular/material/expansion'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatMenuModule} from '@angular/material/menu'
import {MatSelectModule} from '@angular/material/select'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {MatSortModule} from '@angular/material/sort'
import {MatTableModule} from '@angular/material/table'
import {MatTabsModule} from '@angular/material/tabs'
import {MatTooltip} from '@angular/material/tooltip'
import {BrowserModule} from '@angular/platform-browser'
import {provideAnimations} from '@angular/platform-browser/animations'
import {provideRouter, withHashLocation} from '@angular/router'
import {BankIdModule} from '@jhc/bankid'
import {QrModule} from '@jhc/qr'
import {WebcamModule} from 'ngx-webcam'
import routes from './app-routes'
import {AuthInterceptor} from './application/auth-interceptor'
import {CameraFactory} from './application/camera-factory.class'
import {CAMERA_FACTORY} from './application/camera.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/local-storage.provider'
import {ResponseInterceptor} from './application/response.interceptor'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {ConfigService} from './services/config.service'

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, BankIdModule, MatButtonModule, MatInputModule, MatSelectModule, MatMenuModule, MatIconModule, MatDialogModule, ReactiveFormsModule, MatSnackBarModule, MatTableModule, MatSortModule, MatTabsModule, WebcamModule, MatButtonModule, QrModule, MatExpansionModule, MatTooltip, FormsModule),
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    {provide: CAMERA_FACTORY, useClass: CameraFactory},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        appearance: 'outline'
      }
    },
    LOCAL_STORAGE_PROVIDERS,
    WINDOW_PROVIDERS,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(routes,
      withHashLocation())
  ]
}