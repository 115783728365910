import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable, tap} from 'rxjs'
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject'
import {map} from 'rxjs/operators'
import {webSocket} from 'rxjs/webSocket'
import {environment} from '../../environments/environment'
import {ISession, IUser, Session} from '../application/types'
import {ConfigService} from './config.service'

@Injectable({
  providedIn: 'root'
})

export class FotoSessionService {
  /**
   * Session observable that contains the relevant session information to
   * display to the model. Currently only the modelEmail is relevant.
   */
  public session$: Observable<ISession | null>
  public photographer$: Observable<IUser | null>

  private pSession$: BehaviorSubject<ISession | null> =
    new BehaviorSubject<ISession | null>(null)
  private pPhotographer$: BehaviorSubject<IUser | null> =
    new BehaviorSubject<IUser | null>(null)

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) {
    this.session$ = this.pSession$.asObservable()
    this.photographer$ = this.pPhotographer$.asObservable()
  }

  /**
   * Manages WebSocket sessions for various user sessions.
   * @private
   */
  private webSocketSubjects: Map<string, WebSocketSubject<any>> = new Map()

  // Create session
  public createSessionData(projectId: string): Observable<ISession> {
    const url = `${environment.apiUrl}/session`
    const body = {
      photographerSub: this.configService.getCurrentUserPnr(),
      projectId: projectId
    }
    return this.httpClient.put<ISession>(url, body)
  }

  // Get session and the associated user
  public getSession(sessionId: string) {
    const url = `${environment.apiUrl}/session/${sessionId}`
    return this.httpClient.get<Session>(url).pipe(
      // Make sure to map object from API to class
      map(session => new Session(session.session, session.user)),
      tap((session) => {
        this.pPhotographer$.next(session.user)
        this.pSession$.next(session.session)
      })
    )
  }

  /**
   * Creates a WebSocket session for a given sessionId.t
   * If a session already exists, it returns the existing session.
   * @param sessionId - The unique identifier for the session.
   * @returns WebSocketSubject for the specified session
   */
  createWebSocket(sessionId: string): WebSocketSubject<any> | undefined {
    if (!this.webSocketSubjects.has(sessionId)) {
      // TODO: Change url to web socket, currently we use the same socket for all environments
      const webSocketSubject = webSocket(
        'wss://uu2n9vsft3.execute-api.eu-west-1.amazonaws.com/LIVE'
      )
      this.webSocketSubjects.set(sessionId, webSocketSubject)
    }

    return this.webSocketSubjects.get(sessionId)
  }

  /**
   * Closes the WebSocket session for a given sessionId.
   * @param sessionId - The unique identifier for the session to be closed.
   */
  closeWebSocket(sessionId: string): void {
    const webSocketSubject = this.webSocketSubjects.get(sessionId)

    if (webSocketSubject) {
      webSocketSubject.complete()
      this.webSocketSubjects.delete(sessionId)
    }
  }
}
