<div>
  <h1 class="title">Hej {{ firstName }}!</h1>
  <p class="instruction-holder">Välj ett befintligt projekt eller skapa ett
    nytt till vilket samtyckesavtal ska samlas.</p>
</div>

<p class="text-color">SÖK BLAND AKTIVA PROJEKT:</p>

<div class="search-field-holder">
  <mat-form-field class="foa-search-bar" subscriptSizing="dynamic">
    <input [formControl]="project" matInput>
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>
@if (photoService.isProjectsLoading$() && filteredProjects.length === 0) {
  <foa-loader></foa-loader>
} @else {
  @if (filteredProjects.length !== 0) {
    <p class="latest-project-text">VÄLJ BLAND SENASTE:</p>


    <div class="projects-holder">
      @for (project of filteredProjects; track project) {
        @if (project.active) {
          <div class="project-holder">
            <span class="project-name">{{ project.name }}</span>
            <span class="project-choose"
                  tabindex="-1"
                  (keydown.enter)="openSignSelect(project)"
                  (click)="openSignSelect(project)">Välj</span>
          </div>
        }
      }
    </div>
  } @else {
    <span class="no-projects">Du har inga aktiva projekt för tillfället.</span>
  }
}

<div class="information-holder">
  <span class="information-text">
    (Dina inaktiva projekt hittar du i menyn under <b>Signerade</b>)
  </span>
</div>

<button (click)="createProject()" class="foa-button add-project-button"
        mat-flat-button>
  Skapa nytt projekt
</button>

