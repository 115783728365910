<div class="sign-pre-block-holder">
  <span class="sign-subtitle">SIGNERA TILL PROJEKTET:</span>

  <span class="sign-project-name">{{ photoService.signSelectedProject$()?.name }}</span>

  <button (click)="changeProject()" class="foa-button sign-pre-block-button"
          mat-flat-button>
    Byt projekt
  </button>

  <hr class="sign-separator">
</div>

<!-- Single signing with QR code -->
<button (click)="startSingleSign()" class="foa-button sign-mode-button"
        mat-flat-button>
  <mat-icon>person</mat-icon>
  SINGELSIGNERING
</button>
<span class="sign-select-info-text">
  (Samtyckessignering med en person)
</span>

<!-- Multi signing with QR code -->
<button (click)="startMultiSign()" class="foa-button sign-mode-button"
        mat-flat-button>
  <mat-icon>groups</mat-icon>
  MULTISIGNERING
</button>
<span class="sign-select-info-text">
  (Samtyckessignering med en eller flera personer)
</span>

<!-- Email signing -->
<button (click)="startPreSign()" class="foa-button sign-mode-button email"
        mat-flat-button>
  <mat-icon>mail</mat-icon>
  E-POST SIGNERING
</button>
<span
  class="sign-select-info-text">
  (Samtyckessignering via E-post till en eller flera mottagare)
</span>
<!-- Link signing -->
<button (click)="startLinkSign()" class="foa-button sign-mode-button"
        mat-flat-button>
  <mat-icon>link</mat-icon>
  LÄNKSIGNERING
</button>
<span
  class="sign-select-info-text">
  (Samtyckessignering via länk till en eller flera mottagare, tex för att skicka från laget.se)
</span>
