import {CdkScrollable} from '@angular/cdk/scrolling'
import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'

/**
 * A general dialog for handling regret/confirm situations
 * */
export interface RegretDialogData {
  title: string
  body: string
  confirmDialog?: string //'Bekräfta' is default
  abortDialog?: string //'Ångra' is default
}

@Component({
  selector: 'foa-regret-dialog',
  templateUrl: './regret-dialog.component.html',
  styleUrls: ['./regret-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class RegretDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RegretDialogData
  ) {
  }
}
