import {CdkScrollable} from '@angular/cdk/scrolling'
import {Component, Inject, OnDestroy, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox'
import {MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogContent, MatDialogRef} from '@angular/material/dialog'
import {MatPrefix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {filter, Subscription} from 'rxjs'
import {IConsentItem, IPhotoProject} from '../../application/types'
import {FilterConsentsPipe} from '../../common/filter-consents-pipe/filter-consents.pipe'
import {
  PhotographerConsentsListComponent
} from '../../common/photographer-consents-list/photographer-consents-list.component'
import {ConfigService} from '../../services/config.service'
import {PhotoService} from '../../services/photo.service'
import {RegretDialogComponent} from '../regret-dialog/regret-dialog.component'

@Component({
  selector: 'foa-edit-project-dialog',
  templateUrl: './edit-project-dialog.component.html',
  styleUrl: './edit-project-dialog.component.scss',
  standalone: true,
  imports: [ReactiveFormsModule, CdkScrollable, MatDialogContent, MatIcon, MatPrefix, MatCheckbox, PhotographerConsentsListComponent, MatButton, MatDialogClose, FilterConsentsPipe]
})
export class EditProjectDialogComponent implements OnInit, OnDestroy {

  public consents: IConsentItem[] = []

  public projectId: string = ''

  private sub$: Subscription = new Subscription()

  private project: IPhotoProject = {
    active: false,
    description: '',
    name: '',
    projectId: '',
    photographerSub: '',
    timestamp: 0
  }

  public projectForm: FormGroup = new FormGroup({
    projectId: new FormControl<string>({value: '', disabled: true}),
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.minLength(3), Validators.required]
    }),
    active: new FormControl<boolean>(false),
    description: new FormControl<string>('')
  })

  constructor(
    private configService: ConfigService,
    private photoService: PhotoService,
    private editProjectDialogRef: MatDialogRef<IPhotoProject>,
    private regretDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      project: IPhotoProject
      config: any
    }
  ) {
    this.project = data.project
  }

  ngOnInit(): void {
    this.projectForm.patchValue(this.data.project)
    this.projectId = this.data.project.projectId

    this.sub$.add(this.photoService.consents$.subscribe(
      consents => {
        this.consents = consents
      }))
  }

  ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public changeActive(event: MatCheckboxChange, project: IPhotoProject) {
    project.active = event.checked
    this.photoService.saveProject(project).subscribe()
  }

  public deleteProject(projectId: string) {
    this.regretDialog.open(RegretDialogComponent, {
      data: {
        title: 'Borttagning av projekt',
        body: 'Detta tar bort projektet och går inte att ångra! Inga samtycke eller dyl. tas bort, bara projektet'
      }
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: () => {
          this.photoService.deleteProject(projectId).subscribe({
            next: () => {
              this.editProjectDialogRef.close()
            }
          })
        }
      })
  }

  public close(): void {
    this.project = {
      photographerSub: this.configService.getCurrentUserPnr(),
      active: this.data.project.active,
      description: this.projectForm.controls['description'].value,
      name: this.projectForm.controls['name'].value,
      projectId: this.projectForm.controls['projectId'].value,
      timestamp: this.data.project.timestamp
    }
    this.editProjectDialogRef.close(this.project)
  }
}
