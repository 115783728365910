import {AsyncPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton, MatFabButton} from '@angular/material/button'
import {MatDialogClose, MatDialogRef} from '@angular/material/dialog'
import {MatFormField} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatOption, MatSelect} from '@angular/material/select'
import {filter, Subscription} from 'rxjs'
import {IFileUpload, IUser} from '../../application/types'
import {
  IImageHandlerEvent,
  ImageHandlerComponent
} from '../../common/image-handler/image-handler.component'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'foa-admin-profile-dialog',
  standalone: true,
  imports: [
    MatFormField,
    MatSelect,
    MatOption,
    MatInputModule,
    MatButton,
    ReactiveFormsModule,
    MatIcon,
    MatFabButton,
    AsyncPipe,
    ImageHandlerComponent,
    MatDialogClose
  ],
  templateUrl: './admin-profile-dialog.component.html',
  styleUrls: ['./admin-profile-dialog.component.scss']
})
export class ManagementAdminProfileComponent implements OnInit, OnDestroy {

  public image: string = ''
  public uploadedImage: IFileUpload | null = null

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.email]),
    instagram: new FormControl('', [Validators.required]),
    facebook: new FormControl('', [Validators.required]),
    linkedin: new FormControl('', [Validators.required]),
    unsplash: new FormControl('', Validators.required),
    thankYouMessage: new FormControl('', Validators.required),
    webpage: new FormControl('', Validators.required)
  })

  private userCurrentPhoto?: string
  private sub$ = new Subscription()

  constructor(
    private myPageRef: MatDialogRef<ManagementAdminProfileComponent>,
    protected configService: ConfigService
  ) {
  }

  public ngOnInit() {
    this.sub$ = this.configService.loggedInUser$
      .pipe(filter(Boolean))
      .subscribe({
        next: (user: IUser) => {
          this.userCurrentPhoto = user.photo
          if (user.photoUrl) {
            this.image = user.photoUrl
          }
          this.form.patchValue(user)
        }
      })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public save(): void {
    const user = {
      ...this.form.value,
      sub: this.configService.getCurrentUserPnr(),
      // If the user has taken a photo, use that.
      // Otherwise, use the latest one from the loggedInUser$.
      photo: this.uploadedImage ? this.uploadedImage.id : this.userCurrentPhoto
    } as IUser

    this.configService.updateUser(user).subscribe()
    this.myPageRef.close(true)
  }

  public onImageCaptured(event: IImageHandlerEvent) {
    this.image = event.image
    this.uploadedImage = event.uploadedImage
  }
}
