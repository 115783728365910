<div class="filter-holder">
  <span class="title">SAMTYCKE FRÅN:</span>
  <span class="model-name">
      <mat-icon class="account-circle">account_circle</mat-icon>
    {{ consent.modelName }}
    </span>

  <div class="information-holder">
    <label for="modelSub">MODELL PERSONNUMMER:</label>
    <input
      id="modelSub"
      placeholder="Information saknas"
      [value]="consent.photoSubjectSub ?? consent.modelSub"
      readonly>
  </div>
  <div class="information-holder">
    <label for="time">TIDPUNKT:</label>
    <input
      id="time"
      placeholder="Information saknas"
      [value]="consent.timeStamp | date: 'YYYY-MM-dd, HH:mm'"
      readonly>
  </div>
  <div class="information-holder">
    <label for="modelEmail">EMAIL:</label>
    <input
      id="modelEmail"
      placeholder="Information saknas"
      [value]="consent.modelEmail ?? null"
      readonly>
  </div>
  <div class="information-holder">
    <label for="modelDescription">MODELLBESKRIVNING:</label>
    <input
      id="modelDescription"
      placeholder="Information saknas"
      [value]="consent.modelDescription ?? null"
      readonly>
  </div>
  <div class="information-holder">
    <label for="photographer">FOTOGRAF:</label>
    <input
      id="photographer"
      placeholder="Information saknas"
      [value]="(consent.photographerSub | slice:0:8) + '-' + (consent.photographerSub | slice:8)"
      readonly>
  </div>
  <div class="information-holder">
    <label for="project">PROJEKT:</label>
    <input
      id="project"
      placeholder="Information saknas"
      [value]="project?.name"
      readonly>
  </div>
  <div class="information-holder">
    <label for="itemId">SIGNERINGS ID:</label>
    <input
      id="itemId"
      placeholder="Information saknas"
      [value]="consent.itemId"
      readonly>
  </div>
  @if (consent.photo) {
    <div class="image-holder">
      <img class="model-selfie" id="image" alt="Modell selfie"
           [src]="consent.photoUrl">
    </div>
  }

  <div class="consent-details-buttons-holder" matDialogActions>
    <button class="foa-button" mat-flat-button [mat-dialog-close]="null">
      Stäng
    </button>
    <button class="foa-button" mat-flat-button
            (click)="openDialog(consent.itemId)">
      Ta bort samtycke
    </button>
  </div>
</div>
