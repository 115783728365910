import {Component, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {ActivatedRoute, Router} from '@angular/router'
import {QrModule} from '@jhc/qr'
import {
  HOME_ROUTE_PATH,
  ROUTE_PARAM_PROJECT_ID, SIGN_MODE_SINGLE_ROUTE_PATH
} from '../../application/constants'
import {
  IImageHandlerEvent,
  ImageHandlerComponent
} from '../../common/image-handler/image-handler.component'
import {LoaderComponent} from '../../common/loader/loader.component'
import {PhotoService} from '../../services/photo.service'
import {
  ManagementSignModeHeaderComponent
} from '../sign-mode-header/management-sign-mode-header.component'


@Component({
  selector: 'foa-management-sign-mode-single',
  templateUrl: './management-sign-mode-single.component.html',
  standalone: true,
  imports: [
    QrModule,
    MatButton,
    ImageHandlerComponent,
    ManagementSignModeHeaderComponent,
    LoaderComponent
  ],
  styleUrls: ['../sign-styles.scss', './management-sign-mode-single.component.scss']
})
export class ManagementSignModeSingleComponent implements OnInit {

  public imageEvent: IImageHandlerEvent = {image: '', uploadedImage: null}

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private photoService: PhotoService
  ) {
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const projectId = params.get(ROUTE_PARAM_PROJECT_ID)!

      // Recover selected project from service and check it is the same we
      // have in params. If not, navigate to home screen.
      if (!projectId ||
        this.photoService.signSelectedProject$()?.projectId !== projectId) {
        this.router.navigate(['/', HOME_ROUTE_PATH]).then()
      }
    })
  }

  public goToQrCodeScreen() {
    this.router.navigate(['/', SIGN_MODE_SINGLE_ROUTE_PATH,
      this.photoService.signSelectedProject$()?.projectId,
      this.imageEvent.uploadedImage?.id])
      .then()
  }
}
