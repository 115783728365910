import {AsyncPipe} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {HOME_ROUTE_PATH, ROUTE_PARAM_SIGNATURE_REF} from '../../application/constants'
import {IConsentItem} from '../../application/types'
import {ImageHandlerComponent} from '../../common/image-handler/image-handler.component'
import {EmailService} from '../../services/email.service'
import {FotoSessionService} from '../../services/foto-session.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-model-signing-result',
  templateUrl: './model-signing-result.component.html',
  styleUrls: ['./model-signing-result.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatButton, ImageHandlerComponent, AsyncPipe]
})
export class ModelSigningResultComponent implements OnInit {

  public contactForm: FormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.required,
      Validators.minLength(1), Validators.email])
  })

  public accessToken!: string
  public allDone = false
  public emailCopy = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailService: EmailService,
    protected fotoSessionService: FotoSessionService,
    private photoService: PhotoService
  ) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (params.has(ROUTE_PARAM_SIGNATURE_REF)) {
          this.accessToken = params.get(ROUTE_PARAM_SIGNATURE_REF) as string
          // Get user info only after assuring that we have a token.
          // Avoid unnecessary calls to API
          this.getUserInfo()
        } else {
          this.router.navigate([HOME_ROUTE_PATH]).then()
        }
      }
    })
  }

  private getUserInfo() {
    this.photoService.getConsentById(this.accessToken).subscribe({
      next: (me: IConsentItem) => {
        // If the URL contains an invalid token it will result in an empty
        // UserInfo. In that case, people might be URL-guessing. Move them away.
        if (!me) {
          this.router.navigate([HOME_ROUTE_PATH]).then()
          return
        }
        // If "modelEmail" is present, it means that it has been done already
        if (me.modelEmail) {
          this.contactForm.controls['email'].setValue(me.modelEmail)
        }
      }
    })
  }

  public skip(): void {
    this.allDone = true
  }

  public sendEmail(): void {
    this.emailService.sendEmail(
      this.accessToken,
      this.contactForm.controls['email'].value
    ).subscribe({
      next: () => {
        this.allDone = true
        this.emailCopy = true
      }
    })
  }
}
