import {Component, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {ActivatedRoute, Router} from '@angular/router'
import {QrModule} from '@jhc/qr'
import {environment} from '../../../../environments/environment'
import {
  HOME_ROUTE_PATH,
  MODEL_ROUTE_PATH,
  MODEL_WELCOME_ROUTE_PATH,
  REGISTERED_ROUTE_PATH,
  ROUTE_PARAM_PHOTO_ID,
  ROUTE_PARAM_PROJECT_ID
} from '../../../application/constants'
import {FotoSessionService} from '../../../services/foto-session.service'
import {PhotoService} from '../../../services/photo.service'
import {
  ManagementSignModeHeaderComponent
} from '../../sign-mode-header/management-sign-mode-header.component'

@Component({
  selector: 'foa-management-sign-mode-single',
  templateUrl: './management-sign-mode-single-code.component.html',
  standalone: true,
  imports: [
    QrModule,
    MatButton,
    ManagementSignModeHeaderComponent
  ],
  styleUrls: ['../../sign-styles.scss', './management-sign-mode-single-code.component.scss']
})
export class ManagementSignModeSingleCodeComponent implements OnInit {

  protected signUrl = environment.theUrl + '/#/' +
    MODEL_ROUTE_PATH + '/' + MODEL_WELCOME_ROUTE_PATH
  protected readonly environment = environment

  private generatedSessionId = ''
  private photoId = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private photoService: PhotoService,
    private fotoSessionService: FotoSessionService
  ) {
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const projectId = params.get(ROUTE_PARAM_PROJECT_ID)!
      this.photoId = params.get(ROUTE_PARAM_PHOTO_ID)!

      // Recover selected project from service and check it is the same we
      // have in params. If not, navigate to home screen.
      if (!projectId || !this.photoId ||
        this.photoService.signSelectedProject$()?.projectId !== projectId) {
        this.router.navigate(['/', HOME_ROUTE_PATH]).then()
      }

      this.fotoSessionService.createSessionData(projectId)
        .subscribe({
          next: (session) => {
            this.generatedSessionId = session.sessionId
            this.signUrl += `/${this.generatedSessionId}?${ROUTE_PARAM_PHOTO_ID}=${this.photoId}`
          }
        })
    })
  }

  public moveOn(): void {
    this.router.navigate(['/', MODEL_ROUTE_PATH,
        MODEL_WELCOME_ROUTE_PATH, this.generatedSessionId],
      {
        queryParams: {
          [ROUTE_PARAM_PHOTO_ID]: this.photoId
        }
      })
      .then()
  }

  public close(): void {
    this.router.navigate([REGISTERED_ROUTE_PATH]).then()
  }
}
