<div class="sign-pre-block-holder">
  <span class="sign-subtitle">{{ title() }}</span>

  <span class="sign-project-name">{{ photoService.signSelectedProject$()?.name }}</span>

  <button class="foa-button sign-pre-block-button" mat-flat-button
          (click)="onBackClick()">
    Tillbaka
  </button>

  <hr class="sign-separator">
</div>