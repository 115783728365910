import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes'
import {DatePipe, NgForOf, SlicePipe} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatOption} from '@angular/material/autocomplete'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatChip, MatChipGrid, MatChipInput, MatChipInputEvent, MatChipsModule} from '@angular/material/chips'
import {MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {MatSnackBar} from '@angular/material/snack-bar'
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip'
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {filter} from 'rxjs'
import {WebSocketSubject} from 'rxjs/internal/observable/dom/WebSocketSubject'
import {
  ManagementSignModeHeaderComponent
} from '../../4-management/sign-mode-header/management-sign-mode-header.component'
import {HOME_ROUTE_PATH, ROUTE_PARAM_SESSION_ID} from '../../application/constants'
import {IKidModel, IMinorSignModel} from '../../application/types'
import {LoaderComponent} from '../../common/loader/loader.component'
import {RegretDialogComponent} from '../../dialogs/regret-dialog/regret-dialog.component'
import {EmailService} from '../../services/email.service'
import {FotoSessionService} from '../../services/foto-session.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-model-signing-minor',
  standalone: true,
  imports: [
    DatePipe,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel,
    SlicePipe,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    NgForOf,
    MatIconButton,
    MatChipGrid,
    MatChip,
    MatIcon,
    MatChipInput,
    MatChipsModule,
    LoaderComponent,
    ManagementSignModeHeaderComponent,
    MatTooltip,
    MatTooltipModule
  ],
  templateUrl: './model-signing-minor.component.html',
  styleUrls: ['./model-signing-minor.component.scss']
})
export class ModelSigningMinorComponent implements OnInit {

  public recipients: IMinorSignModel[] = []
  public kids: IKidModel[] = []
  public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON]
  public sessionId = ''
  public photoId?: string
  public image: string = ''
  public display: boolean = false
  public sessionState: boolean = false

  public form = new FormGroup({
    pNr: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(3)]
    }),
    recipient: new FormControl('', [Validators.email]),
    message: new FormControl('', {
      nonNullable: true
    })
  })

  private webSocketSubject?: WebSocketSubject<any>

  constructor(
    protected photoService: PhotoService,
    protected fotoSessionService: FotoSessionService,
    protected emailService: EmailService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  public ngOnInit() {
    this.webSocketSubject = this.fotoSessionService.createWebSocket('uffe')
    this.webSocketSubject?.subscribe()

    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (params.has(ROUTE_PARAM_SESSION_ID)) {
          this.sessionId = params.get(ROUTE_PARAM_SESSION_ID)!
        }
      }
    })

    this.fotoSessionService.getSession(this.sessionId)
      .subscribe({
        next: (session) => {
          if (session) {
            this.display = true
            this.sessionState = true
            if (session.user.photoUrl) {
              this.image = session.user.photoUrl
            }
          }
        },
        error: () => {
          this.display = true
        }
      })
  }

  public addPNr(event: MatChipInputEvent): void {
    const value = event.value.trim()

    // Make sure that control's value is trimmed before its validity check
    this.form.controls.pNr.setValue(value)

    if (this.form.controls.pNr.valid && event.value) {
      this.kids.push({pNr: value})
      event.chipInput.clear()
      this.form.updateValueAndValidity()
    }
  }

  public removePNr(pNr: string): void {
    const index = this.kids.findIndex((r: IKidModel) =>
      r.pNr === pNr)
    this.kids.splice(index, 1)
    this.form.updateValueAndValidity()
  }

  public addRecipient(event: MatChipInputEvent): void {
    const value = event.value.trim()

    // Make sure that control's value is trimmed before its validity check
    this.form.controls.recipient.setValue(value)

    if (this.form.controls.recipient.valid && event.value) {
      // Remove value from recipient's input once we know it's valid
      this.form.controls.recipient.setValue(null)

      this.recipients.push({guardianEmail: value})
      event.chipInput.clear()
      this.form.updateValueAndValidity()
    }
  }

  public removeRecipient(recipient: string): void {
    const index = this.recipients.findIndex((r: IMinorSignModel) =>
      r.guardianEmail === recipient)
    this.recipients.splice(index, 1)
    this.form.updateValueAndValidity()
  }

  public sendGuardianSignEmail() {
    this.dialog.open(RegretDialogComponent, {
      data: {
        title: 'Skicka förfrågan för samtycke',
        body: 'Om du accepterar så skickas epost till samtliga vårdnadshavare'
      }
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: () => {
          const message = this.form.controls.message.value
          this.emailService.sendGuardianSignEmail(
            this.photoService.signSelectedProject$()!.projectId,
            this.recipients, 'Signering minderårig',
            message
          ).subscribe(() => {
            this.snackbar.open('Epost har skickats!', 'Okej!')
            this.router.navigate([HOME_ROUTE_PATH]).then()
          })
        }
      })
  }
}
