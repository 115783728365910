import {Component, inject, input} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {Router} from '@angular/router'
import {environment} from '../../../environments/environment'
import {SIGN_SELECT_ROUTE_PATH} from '../../application/constants'
import {PhotoService} from '../../services/photo.service'


@Component({
  selector: 'foa-management-sign-mode-header',
  templateUrl: './management-sign-mode-header.component.html',
  standalone: true,
  imports: [
    MatButton
  ],
  styleUrls: ['../sign-styles.scss']
})
export class ManagementSignModeHeaderComponent {

  public title = input.required<string>()

  protected readonly environment = environment

  protected photoService = inject(PhotoService)
  private router: Router = inject(Router)

  public onBackClick() {
    this.router.navigate(
      ['/', SIGN_SELECT_ROUTE_PATH,
        this.photoService.signSelectedProject$()?.projectId])
      .then()
  }
}
