<h2 class="sign-title">Information om minderårig</h2>
<span class="information">Ange personnummer till den/de minderåriga samt om någon vårdnadshavare inte är närvarande, dess epost och ett eventuellt meddelande. Sätt komma mellan varje personnummer/epost.</span>

<form [formGroup]="form" class="pre-sign-form-holder">
  <div class="input-holder wrap-placeholder">
    <label class="recipient-align" for="pNr">MINDERÅRIGAS PERSONNUMMER:
      <mat-icon #tooltip1="matTooltip" (click)="tooltip1.show()"
                (keydown.enter)="tooltip.show()"
                class="tooltip-icon"
                matTooltip="Komma mellan personnummer vid flera barn.">info
      </mat-icon>
    </label>
    <mat-chip-grid #chipGrid1 aria-label="Personnummer">
      <mat-chip
        (removed)="removePNr(kid.pNr)"
        *ngFor="let kid of kids">
        {{ kid.pNr }}
        <button matChipRemove>
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-grid>

    <input
      (matChipInputTokenEnd)="addPNr($event)"
      [matChipInputAddOnBlur]="true"
      [matChipInputFor]="chipGrid1"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      formControlName="pNr"
      id="pNr"
      placeholder="Ex: 010101-0101"
    >
  </div>

  <div class="input-holder wrap-placeholder">
    <label class="recipient-align" for="recipient">FRÅNVARANDE VÅRDNADSHAVARE:
      <mat-icon #tooltip="matTooltip" (click)="tooltip.show()"
                (keydown.enter)="tooltip.show()"
                class="tooltip-icon"
                matTooltip="Komma mellan epostadresserna vid flera vårdnadshavare.">info
      </mat-icon>
    </label>
    <mat-chip-grid #chipGrid aria-label="Mottagare">
      <mat-chip
        (removed)="removeRecipient(recipient.guardianEmail)"
        *ngFor="let recipient of recipients">
        {{ recipient.guardianEmail }}
        <button matChipRemove>
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-grid>

    <input
      (matChipInputTokenEnd)="addRecipient($event)"
      [matChipInputAddOnBlur]="true"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      formControlName="recipient"
      id="recipient"
      placeholder="Ex: exempel@exempel.com"
    >
  </div>

  <div class="text-area-holder">
    <label for="message">MEDDELANDE:</label>
    <textarea formControlName="message"
              id="message"
              ngDefaultControl
              placeholder="Ex: Här kommer en länk till signering av samtyckesavtal">
      </textarea>
  </div>
</form>

@if (emailService.isSendingPreSignEmail$()) {
  <foa-loader></foa-loader>
} @else {
  <button class="foa-button email-send-button" mat-flat-button
          [disabled]="recipients.length < 1 || form.invalid"
          (click)="sendGuardianSignEmail()">
    Skicka och/eller signera
  </button>
}
