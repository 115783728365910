import {HttpClient} from '@angular/common/http'
import {Injectable, signal} from '@angular/core'
import {NEVER, Observable, tap} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {IMinorSignModel, IPreSignModel} from '../application/types'
import {ConfigService} from './config.service'

@Injectable({
  providedIn: 'root'
})

export class EmailService {
  public isSendingPreSignEmail$ = signal(false)
  public isSendingGuardianEmail$ = signal(false)

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService) {
  }

  public sendEmail(itemId: string, email: string): Observable<boolean> {
    const url = `${environment.apiUrl}/email`
    const body = {
      itemId: itemId,
      email: email
    }
    return this.httpClient.put<any>(url, body)
  }

  public sendPreSignEmail(projectId: string, recipients: IPreSignModel[], subject: string, message: string): Observable<any> {
    const url = `${environment.apiUrl}/presign`
    const body = {
      photographerSub: this.configService.getCurrentUserPnr(),
      projectId: projectId,
      photographerMessage: message,
      subject: subject,
      recipients: recipients
    }

    this.isSendingPreSignEmail$.set(true)
    return this.httpClient.put<any>(url, body)
      .pipe(
        tap(() => this.isSendingPreSignEmail$.set(false)),
        catchError(() => {
          this.isSendingPreSignEmail$.set(false)
          return NEVER
        })
      )
  }

  public sendGuardianSignEmail(projectId: string, recipients: IMinorSignModel[], subject: string, message: string): Observable<any> {
    const url = `${environment.apiUrl}/guardian`
    const body = {
      photographerSub: this.configService.getCurrentUserPnr(),
      projectId: projectId,
      photographerMessage: message,
      subject: subject,
      recipients: recipients
    }

    this.isSendingGuardianEmail$.set(true)
    return this.httpClient.put<any>(url, body)
      .pipe(
        tap(() => this.isSendingGuardianEmail$.set(false)),
        catchError(() => {
          this.isSendingGuardianEmail$.set(false)
          return NEVER
        })
      )
  }
}
