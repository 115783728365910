import {AsyncPipe} from '@angular/common'
import {Component, Input} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {MatTooltip} from '@angular/material/tooltip'
import {RouterLink} from '@angular/router'
import {
  ABOUT_ROUTE_PATH,
  ADMIN_ROUTE_PATH,
  HOME_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  REGISTERED_ROUTE_PATH
} from '../../application/constants'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'foa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [RouterLink, MatIcon, MatTooltip, MatButton, MatMenuTrigger, MatMenu, MatMenuItem, AsyncPipe]
})
export class HeaderComponent {
  /**
   * The accessToken, used to check if the user is logged in
   */
  @Input() token: string | null | undefined = ''

  protected readonly LOGIN_ROUTE_PATH = LOGIN_ROUTE_PATH
  protected readonly ADMIN_ROUTE_PATH = ADMIN_ROUTE_PATH
  protected readonly REGISTERED_ROUTE_PATH = REGISTERED_ROUTE_PATH
  protected readonly HOME_ROUTE_PATH = HOME_ROUTE_PATH
  protected readonly ABOUT_ROUTE_PATH = ABOUT_ROUTE_PATH

  constructor(public configService: ConfigService) {
  }
}
