import {Pipe, PipeTransform} from '@angular/core'
import {IConsentItem} from '../../application/types'

@Pipe({
  name: 'filterConsents',
  standalone: true
})
export class FilterConsentsPipe implements PipeTransform {
  transform(consents: IConsentItem[], projectId: string): IConsentItem[] {
    if (projectId) {
      return consents.filter(consent => consent.projectId === projectId)
    } else {
      return consents
    }
  }
}