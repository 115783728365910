import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {Observable, of} from 'rxjs'
import {ConfigService} from '../services/config.service'
import {LOGIN_ROUTE_PATH} from './constants'

export const authGuard = (): Observable<boolean | Promise<boolean>> => {
  const router = inject(Router)
  const configService = inject(ConfigService)

  if (!configService.isLoggedIn$()) {
    router.navigate([LOGIN_ROUTE_PATH]).then()
  }

  return of(configService.isLoggedIn$())
}
