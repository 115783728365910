@if (display) {
  @if (!image) {
    <p class="sign-info-text">För att känna igen dig på de bilder du är med på
      behöver jag en ansiktsbild av dig (eller ditt barn om du signerar för
      det).</p>
    <p class="sign-info-text">Bilden kommer INTE publiceras utan används bara
      internt för identifiering av de bilder samtycket gäller.</p>

    <button class="foa-button take-photo-button" mat-flat-button
            (click)="openCam()">
      Ta en selfie
    </button>

    <p class="sign-info-text">Vill du inte ta en selfie så skulle en beskrivning
      av dig vara till stor hjälp vid identifiering.</p>

    <div class="description">
      <label for="modelDescription" class="text-color">
        BESKRIVNING AV DIG:
      </label>
      <input
        [formControl]="modelDescriptionCtrl" id="modelDescription"
        placeholder="Ex. Rött uppsatt hår, rutig skjorta">
    </div>
  } @else {
    <div class="instruction-holder">
      <span>Liknar bilden dig?</span>
      <span>Är du ensam på bilden?</span>
      <span>Om inte, ta om bilden.</span>
      <br>
      <span>Tack!</span>
    </div>

  }

  <foa-image-handler
    (imageCaptured)="onImageCaptured($event)"
    [imageAsString]="image"
    [retakeButton]="true"
    [size]="100"
  ></foa-image-handler>

  @if (image) {
    <hr class="black-line">
  }

  @if (uploadedImage || modelDescriptionCtrl.valid) {

    <jhc-bankid
      (accessToken)="signCompleted($event)"
      [apiUrl]="environment.apiUrl"
      [bankIdUrl]="environment.bankIdUrl"
      [serviceType]="environment.serviceType"
      [userRef]="sessionId"
    ></jhc-bankid>
  }
}
