@if (!allDone) {
  <form [formGroup]="contactForm">
    <div class="instruction-holder">
      <h2>Kopia på samtycket?</h2>
      <span class="instruction-text">Önskar du en kopia på samtycket ber vi dig att fylla i din epostadress.</span>
    </div>
    <div class="email-input">
      <label for="email">EPOST:</label>
      <input formControlName="email" ngDefaultControl
             id="email"
             placeholder="Ex. eva@gmail.com"
             type="email">
    </div>
    <div class="button-holder">
      <button type="button" (click)="skip()" class="buttons" mat-flat-button>
        Hoppa
        över
      </button>
      <button (click)="sendEmail()"
              [disabled]="contactForm.invalid"
              class="buttons"
              mat-flat-button>
        Skicka
      </button>
    </div>
    <div class="information-holder">
            <span>Vill du återkalla ditt samtycke ber vi dig att logga in på <span
              class="underline-url">consent.sealthedeal.se</span> med BankID.</span>
      <span>Där administrerar du enkelt de samtycken du gjort.</span>
    </div>
  </form>
} @else {
  @if (emailCopy) {
    <span class="email-confirmation">Snart får du ett e-post meddelande med en kopia på samtycket.</span>
  }
  <h2 class="title">Tack!</h2>
  <foa-image-handler
    [imageAsString]="(fotoSessionService.photographer$ | async)?.photoUrl ?? ''"
    [size]="100"
    [circularImage]="true"
  ></foa-image-handler>
  <div class="photographer-information">
    <div class="photographer-information-thanks">
      {{ (fotoSessionService.photographer$ | async)?.thankYouMessage }}
    </div>
    <br>
    <div class="photographer-information-size">
      <span>{{ (fotoSessionService.photographer$ | async)?.phoneNumber }}</span>
      <span class="underline-url">
        <a target="_blank"
           [href]="(fotoSessionService.photographer$ | async)?.webpage">
        {{ (fotoSessionService.photographer$ | async)?.webpage }}</a></span>
    </div>
    <div class="social-media-holder">
      @if ((fotoSessionService.photographer$ | async)?.instagram) {
        <p><a target="_blank"
              [href]="(fotoSessionService.photographer$ | async)?.instagram"><img
          class="social-media-icon" alt=""
          src="assets/instagram.svg"></a></p>
      }
      @if ((fotoSessionService.photographer$ | async)?.facebook) {
        <p><a target="_blank"
              [href]="(fotoSessionService.photographer$ | async)?.facebook"><img
          class="social-media-icon" alt=""
          src="assets/square-facebook.svg"></a></p>
      }
      @if ((fotoSessionService.photographer$ | async)?.linkedin) {
        <p><a target="_blank"
              [href]="(fotoSessionService.photographer$ | async)?.linkedin"><img
          class="social-media-icon" alt=""
          src="assets/linkedin.svg"></a></p>
      }
      @if ((fotoSessionService.photographer$ | async)?.unsplash) {
        <p><a target="_blank"
              [href]="(fotoSessionService.photographer$ | async)?.unsplash"><img
          class="social-media-icon" alt=""
          src="assets/unsplash.svg"></a></p>
      }
    </div>
  </div>
}
