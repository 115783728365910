import {Component} from '@angular/core'

@Component({
  selector: 'foa-loader',
  standalone: true,
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
}
