import {Routes} from '@angular/router'
import {LoginComponent} from './1-login/login.component'
import {AboutComponent} from './2-about/about.component'
import {ModelSigningResultComponent} from './3-model-signing/result/model-signing-result.component'
import {ModelSigningMinorComponent} from './3-model-signing/sign-minor/model-signing-minor.component'
import {ModelSigningSignComponent} from './3-model-signing/sign/model-signing-sign.component'
import {ModelSigningWelcomeComponent} from './3-model-signing/welcome/model-signing-welcome.component'
import {ManagementAdminComponent} from './4-management/admin/management-admin.component'
import {ManagementHomeComponent} from './4-management/home/management-home.component'
import {ManagementRegisteredComponent} from './4-management/registered/management-registered.component'
import {ManagementSignModeEmailComponent} from './4-management/sign-mode-email/management-sign-mode-email.component'
import {ManagementSignModeMultiComponent} from './4-management/sign-mode-multi/management-sign-mode-multi.component'
import {
  ManagementSignModeSingleCodeComponent
} from './4-management/sign-mode-single/code/management-sign-mode-single-code.component'
import {ManagementSignModeSingleComponent} from './4-management/sign-mode-single/management-sign-mode-single.component'
import {ManagementSignSelectComponent} from './4-management/sign-select/management-sign-select.component'
import {authGuard} from './application/auth.guard'
import {
  ABOUT_ROUTE_PATH,
  ADMIN_ROUTE_PATH,
  HOME_ROUTE_PATH,
  MINOR_ROUTE_PATH,
  MODEL_ROUTE_PATH,
  MODEL_WELCOME_ROUTE_PATH,
  REGISTERED_ROUTE_PATH,
  ROUTE_PARAM_PHOTO_ID,
  ROUTE_PARAM_PROJECT_ID,
  ROUTE_PARAM_SESSION_ID,
  ROUTE_PARAM_SIGNATURE_REF,
  SIGN_MODE_EMAIL_ROUTE_PATH,
  SIGN_MODE_MULTI_ROUTE_PATH,
  SIGN_MODE_SINGLE_ROUTE_PATH,
  SIGN_ROUTE_PATH,
  SIGN_SELECT_ROUTE_PATH,
  SIGNUP_ROUTE_PATH
} from './application/constants'

export default [
  // Public routes: login, about and model-signing-process screens
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: ABOUT_ROUTE_PATH,
    component: AboutComponent
  },
  {
    path: 'arkad',
    loadChildren: () => import('./3a-arkad/arkad.routes')
  },
  {
    path: `${MODEL_ROUTE_PATH}/${SIGN_ROUTE_PATH}/:${ROUTE_PARAM_SESSION_ID}`,
    pathMatch: 'full',
    component: ModelSigningSignComponent
  },
  {
    path: `${MODEL_ROUTE_PATH}/${SIGNUP_ROUTE_PATH}/:${ROUTE_PARAM_SIGNATURE_REF}`,
    pathMatch: 'full',
    component: ModelSigningResultComponent
  },
  {
    path: `${MODEL_ROUTE_PATH}/${MODEL_WELCOME_ROUTE_PATH}/:${ROUTE_PARAM_SESSION_ID}`,
    pathMatch: 'full',
    component: ModelSigningWelcomeComponent
  },
  {
    path: `${MINOR_ROUTE_PATH}/${MODEL_WELCOME_ROUTE_PATH}/:${ROUTE_PARAM_SESSION_ID}`,
    pathMatch: 'full',
    component: ModelSigningMinorComponent
  },
  // Private routes
  {
    path: `${SIGN_SELECT_ROUTE_PATH}/:${ROUTE_PARAM_PROJECT_ID}`,
    pathMatch: 'full',
    component: ManagementSignSelectComponent,
    canActivate: [authGuard]
  },
  {
    path: `${SIGN_MODE_SINGLE_ROUTE_PATH}/:${ROUTE_PARAM_PROJECT_ID}/:${ROUTE_PARAM_PHOTO_ID}`,
    pathMatch: 'full',
    component: ManagementSignModeSingleCodeComponent,
    canActivate: [authGuard]
  },
  {
    path: `${SIGN_MODE_SINGLE_ROUTE_PATH}/:${ROUTE_PARAM_PROJECT_ID}`,
    pathMatch: 'full',
    component: ManagementSignModeSingleComponent,
    canActivate: [authGuard]
  },
  {
    path: `${SIGN_MODE_MULTI_ROUTE_PATH}/:${ROUTE_PARAM_PROJECT_ID}`,
    component: ManagementSignModeMultiComponent,
    canActivate: [authGuard]
  },
  {
    path: `${SIGN_MODE_EMAIL_ROUTE_PATH}/:${ROUTE_PARAM_PROJECT_ID}`,
    pathMatch: 'full',
    component: ManagementSignModeEmailComponent,
    canActivate: [authGuard]
  },
  {
    path: HOME_ROUTE_PATH,
    component: ManagementHomeComponent,
    canActivate: [authGuard]
  },
  {
    path: ADMIN_ROUTE_PATH,
    component: ManagementAdminComponent,
    canActivate: [authGuard]
  },
  {
    path: REGISTERED_ROUTE_PATH,
    component: ManagementRegisteredComponent,
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
] satisfies Routes
