<div matDialogTitle>{{ data.title }}</div>

<div matDialogContent>
  <p>{{ data.body }}</p>

  <div class="regret-buttons-holder" matDialogActions>
    <button class="foa-button" mat-flat-button [mat-dialog-close]="null">
      Ångra
    </button>

    <button class="foa-button" mat-flat-button [mat-dialog-close]="true">
      Bekräfta
    </button>
  </div>
</div>

