import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Inject, Injectable} from '@angular/core'
import {Observable, of} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {IFileUpload, IImageResponse} from '../application/types'
import {WINDOW} from '../application/window.provider'

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(
    private httpClient: HttpClient,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  public uploadImageData(imageFile: IFileUpload): Observable<IFileUpload> {
    const headers = {
      'Content-Type': imageFile.contentType,
      'Cache-Control': 'max-age=4000'
    }

    // First we get an AWS put-URL from our BE and image ID
    const url = `${environment.apiUrl}/images/tmp`
    return this.httpClient
      .put<IImageResponse>(url, {contentType: imageFile.contentType})
      .pipe(
        switchMap((response: IImageResponse) => {
          const httpOptions = {
            headers: new HttpHeaders(headers)
          }
          // Then, we upload image data directly into AWS with that put-url
          return this.httpClient
            .put<void>(response.signedUrl, imageFile.imageData, httpOptions)
            .pipe(map(() => response))
        }),
        map((response: IImageResponse) => {
          // Lastly, we update our file's ID with the one generated in BE
          imageFile.id = response.id
          return imageFile
        })
      )
  }

  public getViewUrl(id: string): Observable<string> {
    const url = `${environment.apiUrl}/images/${id}`
    return this.httpClient.get(url).pipe(
      switchMap((res: any) => of(res.signedUrl))
    )
  }

  public openImage(id: string): void {
    this.getViewUrl(id).subscribe({
      next: (url: string) => this.injectedWindow.open(url, '_blank')
    })
  }

  public deleteImage(id: string): Observable<void> {
    const url = `${environment.apiUrl}/images?id=${id}`
    return this.httpClient.delete<void>(url)
  }
}
