import {CdkScrollable} from '@angular/cdk/scrolling'
import {Component, Inject, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog'
import {WebcamImage, WebcamInitError, WebcamModule} from 'ngx-webcam'
import {Observable, Subject} from 'rxjs'
import {CAMERA_FACTORY, ICameraFactory} from '../../application/camera.provider'

@Component({
  selector: 'foa-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.scss'],
  standalone: true,
  imports: [CdkScrollable, MatDialogContent, WebcamModule, MatDialogActions, MatButton]
})
export class CameraDialogComponent implements OnInit {
  public showWebcam = true
  public allowCameraSwitch = true
  public multipleWebcamsAvailable = false
  public deviceId = '0'
  public facingMode = 'user'
  public errors: WebcamInitError[] = []
  // latest snapshot
  public webcamImage: WebcamImage | undefined

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>()
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>()

  constructor(
    public dialogRef: MatDialogRef<CameraDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(CAMERA_FACTORY) private cameraFactory: ICameraFactory
  ) {
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable()
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable()
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {}
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = {ideal: this.facingMode}
    }
    return result
  }

  public onClose(): void {
    this.dialogRef.close()
  }

  public ngOnInit(): void {
    this.cameraFactory
      .getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1
      })
  }

  public triggerSnapshot(): void {
    this.trigger.next()
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error)
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId)
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage
    this.dialogRef.close(webcamImage)
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId
  }
}
