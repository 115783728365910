<form [formGroup]="form" class="my-page-form-holder">
  <p class="edit-profile-text">REDIGERA PROFIL:</p>
  <div class="name-holder">
    <mat-icon class="account-circle" matPrefix>account_circle</mat-icon>
    <div class="user-name-holder">
      {{ (configService.loggedInUser$ | async)?.name }}
    </div>
  </div>
  <div class="input-holder">
    <label for="name">NAMN:</label>
    <input formControlName="name" id="name" ngDefaultControl
           placeholder="Ex. Kalle Svensson">
  </div>
  <div class="input-holder">
    <label for="phoneNumber">TELEFON:</label>
    <input formControlName="phoneNumber" id="phoneNumber" ngDefaultControl
           placeholder="Ex. 0707 77 76 75">
  </div>
  <div class="input-holder">
    <label for="email">E-POST:</label>
    <input formControlName="instagram" id="email" ngDefaultControl
           placeholder="Ex. johan.andersson@gmail.com">
  </div>
  <div class="input-holder">
    <label for="facebook">FACEBOOK:</label>
    <input formControlName="facebook" id="facebook" ngDefaultControl
           placeholder="Ex. https://www.facebook.com/zuck">
  </div>
  <div class="input-holder">
    <label for="instagram">INSTAGRAM:</label>
    <input formControlName="instagram" id="instagram" ngDefaultControl
           placeholder="Ex. https://www.instagram.com/natgeo/">
  </div>
  <div class="input-holder">
    <label for="linkedin">LINKEDIN:</label>
    <input formControlName="linkedin" id="linkedin" ngDefaultControl
           placeholder="Ex. https://www.linkedin.com/company/national-geographic/">
  </div>
  <div class="input-holder">
    <label for="unsplash">UNSPLASH:</label>
    <input formControlName="unsplash" id="unsplash" ngDefaultControl
           placeholder="https://unsplash.com/s/photos/seals">
  </div>
  <div class="input-holder">
    <label for="webpage">HEMSIDA:</label>
    <input formControlName="webpage" id="webpage" ngDefaultControl
           placeholder="Ex. https://www.nationalgeographic.com/">
  </div>
  <div class="text-area-holder">
    <label for="thankYouMessage">TACK MEDDELANDE:</label>
    <textarea formControlName="thankYouMessage" id="thankYouMessage"
              ngDefaultControl
              placeholder="Ex. Stort tack för samtycket och medverkan i mina bilder!{{'\n\n'}}Vänligen/{{'\n'}}{{(configService.loggedInUser$ | async)?.name}}"> </textarea>
  </div>
  <span class="profile-picture-text">PROFILBILD:</span>
  <foa-image-handler
    (imageCaptured)="onImageCaptured($event)"
    [circularImage]="true"
    [dualButtons]="true"
    [imageAsString]="image"
    [size]="80"
  ></foa-image-handler>
  <div class="action-button-holder">
    <button class="foa-button action-button" mat-dialog-close mat-flat-button>
      Stäng
    </button>
    <button (click)="save()" class="foa-button action-button" mat-flat-button>
      Spara
    </button>
  </div>
</form>
