import {Clipboard} from '@angular/cdk/clipboard'
import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'

@Component({
  selector: 'foa-link-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose
  ],
  templateUrl: './link-dialog.component.html',
  styleUrl: './link-dialog.component.scss'
})
export class LinkDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private clipboard: Clipboard
  ) {
  }

  copyLink() {
    this.clipboard.copy(this.data)
  }
}
