import {Component} from '@angular/core'

@Component({
  selector: 'foa-about',
  standalone: true,
  imports: [],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss'
})
export class AboutComponent {

}
