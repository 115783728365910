import {NgClass} from '@angular/common'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {WebcamImage} from 'ngx-webcam'
import {filter} from 'rxjs'
import {IFileUpload} from '../../application/types'
import {
  CameraDialogComponent
} from '../../dialogs/camera-dialog/camera-dialog.component'
import {ImagesService} from '../../services/images.service'

export interface IImageHandlerEvent {
  image: string
  uploadedImage: IFileUpload | null
}

@Component({
  selector: 'foa-image-handler',
  templateUrl: './image-handler.component.html',
  standalone: true,
  imports: [
    MatIcon,
    MatButton,
    NgClass
  ],
  styleUrls: ['./image-handler.component.scss']
})
export class ImageHandlerComponent {
  @Input({required: true}) imageAsString: string = ''
  @Input({required: true}) size: number = 100
  @Input() dualButtons: boolean = false
  @Input() retakeButton: boolean = false
  @Input() circularImage: boolean = false

  @Output() imageCaptured = new EventEmitter<IImageHandlerEvent>()
  @ViewChild('fileUpload', {static: false}) fileUploadName?: ElementRef<HTMLInputElement>

  public uploadedImage: IFileUpload | null = null

  constructor(
    private imageService: ImagesService,
    private camDialog: MatDialog
  ) {
  }

  public openCam() {
    const dialogRef = this.camDialog.open(CameraDialogComponent, {
      maxHeight: '95%'
    })
    dialogRef.afterClosed()
      .pipe(filter(Boolean))
      .subscribe((result: WebcamImage) => {
        // Reset file-upload input so no image is "cached" for next tries
        if (this.fileUploadName) {
          this.fileUploadName.nativeElement.value = ''
        }
        // Save image as string
        this.imageAsString = result.imageAsDataUrl

        // Get file from WebcamImage
        const mimeType = this.getMimeTypeFromDataUrl(result.imageAsDataUrl)
        const blob = this.createBlob(result.imageAsDataUrl)
        const file = {
          name: 'selfie',
          contentType: mimeType,
          imageData: blob
        }

        // Upload image
        this.uploadImageData(file)
      })
  }

  public onFileChanged(event: any) {
    if (event.target.files.length === 0) {
      return
    }
    const file: File = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      if (file) {
        // Save image as string
        const dataUrl = reader.result as string
        this.imageAsString = dataUrl

        // Create upload file
        const mimeType = file.type
        const blob = this.createBlob(dataUrl)
        const uploadFile = {
          name: file.name,
          contentType: mimeType,
          imageData: blob
        }

        this.uploadImageData(uploadFile)
      }
    }
  }

  private createBlob(base64: string) {
    const base64String = base64.split(',')[1]
    const binaryString = atob(base64String)
    const byteArray = new Uint8Array(binaryString.length)

    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i)
    }

    return new Blob([byteArray], {type: 'image/jpeg'})
  }

  private getMimeTypeFromDataUrl(dataUrl: string): string {
    const match = dataUrl.match(/^data:(.*?);/)
    if (match && match[1]) {
      return match[1]
    }
    return 'unknown'
  }

  private uploadImageData(file: IFileUpload) {
    // Emit event with no-uploaded image (yet)
    this.imageCaptured.emit({
      image: this.imageAsString,
      uploadedImage: null
    })

    const sendImageToApi = () => {
      this.imageService.uploadImageData(file).subscribe({
        next: (uploadedImage: IFileUpload) => {
          this.uploadedImage = uploadedImage
          this.imageCaptured.emit({
            image: this.imageAsString,
            uploadedImage: this.uploadedImage
          })
        }
      })
    }

    // If there was an image before, first we delete that one, and then we
    // upload the new one
    if (this.uploadedImage?.id) {
      this.imageService.deleteImage(this.uploadedImage.id)
        .subscribe(() => {
          this.uploadedImage = null
          sendImageToApi()
        })
    } else {
      sendImageToApi()
    }
  }
}
