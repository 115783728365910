import {Component, Input} from '@angular/core'
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox'
import {MatDialog} from '@angular/material/dialog'
import {MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {filter} from 'rxjs'
import {IConsentItem, IPhotoProject} from '../../application/types'
import {
  EditProjectDialogComponent
} from '../../dialogs/edit-project-dialog/edit-project-dialog.component'
import {PhotoService} from '../../services/photo.service'
import {FilterConsentsPipe} from '../filter-consents-pipe/filter-consents.pipe'
import {LoaderComponent} from '../loader/loader.component'
import {
  PhotographerConsentsListComponent
} from '../photographer-consents-list/photographer-consents-list.component'

@Component({
  selector: 'foa-photographer-projects-list',
  standalone: true,
  imports: [
    MatCheckbox,
    MatIcon,
    MatSuffix,
    PhotographerConsentsListComponent,
    FilterConsentsPipe,
    LoaderComponent
  ],
  templateUrl: './photographer-projects-list.component.html',
  styleUrl: './photographer-projects-list.component.scss'
})
export class PhotographerProjectsListComponent {
  @Input({required: true}) projects: IPhotoProject[] = []
  @Input({required: true}) consents: IConsentItem[] = []
  @Input() actionButtons: boolean = false

  public activeArrow: string = ''

  constructor(
    public photoService: PhotoService,
    private editProjectDialog: MatDialog
  ) {
  }

  public changeActive(event: MatCheckboxChange, project: IPhotoProject) {
    project.active = event.checked
    this.photoService.saveProject(project).subscribe()
  }

  public changeActiveArrow(projectId: string) {
    if (this.activeArrow === projectId) {
      this.activeArrow = ''
    } else {
      this.activeArrow = projectId
    }
  }

  public editProject(project: IPhotoProject) {
    this.editProjectDialog.open(EditProjectDialogComponent, {
      data: {
        project: project,
        config: {title: 'Redigera projekt'}
      }
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(result => {
        this.photoService.saveProject(result).subscribe()
      })
  }
}
