@if (photoService.isProjectsLoading$() && projects.length === 0) {
  <foa-loader></foa-loader>
} @else {
  @for (project of projects; track $index) {
    <!-- Project header row - Name, edit, active and expand -->
    <div class="project-holder">
      <mat-icon (keydown.enter)="changeActiveArrow(project.projectId)"
                (click)="changeActiveArrow(project.projectId)">folder
      </mat-icon>

      <span class="project-name-holder"
            tabindex="-1"
            (keydown.enter)="changeActiveArrow(project.projectId)"
            (click)="changeActiveArrow(project.projectId)">{{ project.name }}</span>

      @if (actionButtons) {
        <mat-icon class="edit-icon" (keydown.enter)="editProject(project)"
                  (click)="editProject(project)">
          edit
        </mat-icon>

        <mat-checkbox (change)="changeActive($event, project)"
                      [checked]=project.active
                      class="checkbox" matSuffix>
        </mat-checkbox>
      }
      <mat-icon
        [class]="'arrow-holder ' + (activeArrow === project.projectId ? 'active' : '')"
        (keydown.enter)="changeActiveArrow(project.projectId)"
        (click)="changeActiveArrow(project.projectId)">
        arrow_upward
      </mat-icon>
    </div>
    <div [hidden]="(activeArrow === project.projectId ? '' : 'hidden')">
      <foa-photographer-consents-list
        [projects]="projects"
        [consents]="consents | filterConsents : project.projectId"
      ></foa-photographer-consents-list>
    </div>
  } @empty {
    <div>
      <br>
      <p class="no-projects">Du har inga projekt att visa.</p>
      <p class="no-projects">Tryck på plusknappen ovan för att lägga till ett
        projekt!</p>
    </div>
  }
}