import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes'
import {DatePipe, NgForOf, SlicePipe} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatOption} from '@angular/material/autocomplete'
import {MatButton, MatIconButton} from '@angular/material/button'
import {
  MatChip,
  MatChipGrid,
  MatChipInput,
  MatChipInputEvent,
  MatChipsModule
} from '@angular/material/chips'
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {MatSnackBar} from '@angular/material/snack-bar'
import {MatTooltip, MatTooltipModule} from '@angular/material/tooltip'
import {ActivatedRoute, Router} from '@angular/router'
import {filter} from 'rxjs'
import {
  HOME_ROUTE_PATH,
  ROUTE_PARAM_PROJECT_ID
} from '../../application/constants'
import {IPreSignModel} from '../../application/types'
import {LoaderComponent} from '../../common/loader/loader.component'
import {
  RegretDialogComponent
} from '../../dialogs/regret-dialog/regret-dialog.component'
import {EmailService} from '../../services/email.service'
import {PhotoService} from '../../services/photo.service'
import {
  ManagementSignModeHeaderComponent
} from '../sign-mode-header/management-sign-mode-header.component'

@Component({
  selector: 'foa-management-sign-mode-email',
  standalone: true,
  imports: [
    DatePipe,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel,
    SlicePipe,
    MatOption,
    MatSelect,
    ReactiveFormsModule,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    NgForOf,
    MatIconButton,
    MatChipGrid,
    MatChip,
    MatIcon,
    MatChipInput,
    MatChipsModule,
    LoaderComponent,
    ManagementSignModeHeaderComponent,
    MatTooltip,
    MatTooltipModule
  ],
  templateUrl: './management-sign-mode-email.component.html',
  styleUrls: ['../sign-styles.scss', './management-sign-mode-email.component.scss']
})
export class ManagementSignModeEmailComponent implements OnInit {

  public recipients: IPreSignModel[] = []
  public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON]

  public form = new FormGroup({
    subject: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(3)]
    }),
    recipient: new FormControl('', [Validators.email]),
    message: new FormControl('', {
      nonNullable: true
    })
  })

  constructor(
    protected photoService: PhotoService,
    protected emailService: EmailService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  public ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const projectId = params.get(ROUTE_PARAM_PROJECT_ID)!

      // Recover selected project from service and check it is the same we
      // have in params. If not, navigate to home screen.
      if (!projectId ||
        this.photoService.signSelectedProject$()?.projectId !== projectId) {
        this.router.navigate(['/', HOME_ROUTE_PATH]).then()
      }

    })
  }

  public addRecipient(event: MatChipInputEvent): void {
    const value = event.value.trim()

    // Make sure that control's value is trimmed before its validity check
    this.form.controls.recipient.setValue(value)

    if (this.form.controls.recipient.valid && event.value) {
      // Remove value from recipient's input once we know it's valid
      this.form.controls.recipient.setValue(null)

      this.recipients.push({modelEmail: value})
      event.chipInput.clear()
      this.form.updateValueAndValidity()
    }
  }

  public removeRecipient(recipient: string): void {
    const index = this.recipients.findIndex((r: IPreSignModel) =>
      r.modelEmail === recipient)
    this.recipients.splice(index, 1)
    this.form.updateValueAndValidity()
  }

  public sendPreSignEmail() {
    this.dialog.open(RegretDialogComponent, {
      data: {
        title: 'Skicka förfrågan för samtycke',
        body: 'Om du accepterar så skickas epostmeddelanden till samtliga modeller'
      }
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe({
        next: () => {
          const message = this.form.controls.message.value
          const subject: string = this.form.controls.subject.value
          this.emailService.sendPreSignEmail(
            this.photoService.signSelectedProject$()!.projectId,
            this.recipients,
            subject,
            message
          ).subscribe(() => {
            this.snackbar.open('Email har skickats!', 'Okej!')
            this.router.navigate([HOME_ROUTE_PATH]).then()
          })
        }
      })
  }
}
