import {AsyncPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption
} from '@angular/material/autocomplete'
import {MatButton, MatIconButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {MatFormField, MatInput, MatSuffix} from '@angular/material/input'
import {filter, Subscription} from 'rxjs'
import {IConsentItem, IPhotoProject} from '../../application/types'
import {
  PhotographerProjectsListComponent
} from '../../common/photographer-projects-list/photographer-projects-list.component'
import {
  ManagementAdminProfileComponent
} from '../../dialogs/admin-profile-dialog/admin-profile-dialog.component'
import {
  EditProjectDialogComponent
} from '../../dialogs/edit-project-dialog/edit-project-dialog.component'
import {ConfigService} from '../../services/config.service'
import {PhotoService} from '../../services/photo.service'

@Component({
  selector: 'foa-management-admin',
  standalone: true,
  imports: [
    MatButton,
    MatIcon,
    MatIconButton,
    MatInput,
    MatFormField,
    MatSuffix,
    MatAutocomplete,
    MatOption,
    AsyncPipe,
    MatAutocompleteTrigger,
    PhotographerProjectsListComponent,
    ReactiveFormsModule
  ],
  templateUrl: './management-admin.component.html',
  styleUrl: './management-admin.component.scss'
})
export class ManagementAdminComponent implements OnInit, OnDestroy {

  public consents: IConsentItem[] = []
  public project = new FormControl('', {nonNullable: true})
  // Using two arrays of PhotoProject to sort through options in the search field
  public projectData: IPhotoProject[] = []
  public projects: IPhotoProject[] = []
  public options: string[] = []
  private subs$: Subscription[] = []

  constructor(
    private dialog: MatDialog,
    protected configService: ConfigService,
    private photoService: PhotoService
  ) {
  }

  ngOnInit() {

    this.photoService.getProjects().subscribe({})

    this.subs$.push(this.photoService.photoProject$
      .subscribe(projects => {
        this.projectData = projects
        this.projects = projects
      }))

    // Filtering the project list when the search field is being used
    this.project.valueChanges.pipe().subscribe(value => {
      this.projectData = this.projects.filter(project =>
        project.name.toLowerCase().startsWith(value.toLowerCase()))
      this.options = this.projectData.map(value => value.name)
    })

    this.photoService.getConsents().subscribe()

    this.subs$.push(this.photoService.consents$.subscribe(consents => {
      this.consents = consents
    }))
  }

  ngOnDestroy() {
    this.subs$.forEach((sub) => sub.unsubscribe())
  }

  public editMyPage(): void {
    this.dialog.open(ManagementAdminProfileComponent, {
      width: '100%',
      maxWidth: 'min(400px, 85vw)',
      height: '100%',
      maxHeight: '95vh'
    })
  }

  public createProject() {
    this.dialog.open(EditProjectDialogComponent, {
      data: {
        project: {
          id: '',
          name: '',
          description: '',
          active: true
        }
      }
    })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(result => {
        this.photoService.createProject(result).subscribe({})
      })
  }
}
